import { PAGE } from '../constants';
import moment from "moment";

const lastSevenBeginDate = moment().tz("Asia/Singapore").subtract(7,'d').format('YYYY-MM-DD');
const lastSevenEndDate = moment().tz("Asia/Singapore").subtract(1,'d').format('YYYY-MM-DD');
let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');
let mauStartDate = moment().tz("Asia/Singapore").subtract(30, 'days').format('YYYY-MM-DD');
let startDatePicTreeMission = moment('2024-09-14').tz("Asia/Tokyo").format('YYYY-MM-DD');
let endDatePicTreeMission = moment().tz("Asia/Tokyo").format('YYYY-MM-DD');
const startDateNtt = moment("2024-12-07").tz("Asia/Tokyo").format('YYYY-MM-DD');
const endDateNtt = moment("2025-01-12").tz("Asia/Tokyo").format('YYYY-MM-DD');

const initialState  = {
    currentPage: '',
    pageTopLoader: false,
    lastSevenBeginDate: lastSevenBeginDate,
    lastSevenEndDate: lastSevenEndDate,
    dateRange: [startDate, endDate],
    dateRangePicTree: [null, null],
    dateRangePicTreeMinMax: [null, null],
    dateRangePicTreeNtt: [startDateNtt, endDateNtt],
    dateRangePicTreeMission: [startDatePicTreeMission, endDatePicTreeMission],
    prevDateRange: [],
    prevDateRangePicTree: [],
    mauDateRange: [mauStartDate, endDate],
    mauPrevDateRange: [],
    wauDateRange: [startDate, endDate],
    wauPrevDateRange: [],
    comparisonDateDiff: 1,
    comparisonDateDiffDisplay: 7,
    tableRowSelected: [],
    idToken: null,
    accessKey: null,
    btnStateDataFetch: false,
    depPrice: {
        loading: false,
        price: '', // last
        usd: 0,
        jpy: 0,
        sgd: 0,
        open_24h: 0,
        open_utc0: 0,
        high_24h: 0,
        low_24h: 0,
        last24: undefined,
        lastFetchTs: 0,
    },
    selectedCurrency: 'dep',
    filterDrawerState: false,
};

export default function pageReducer(state = initialState, action) {
    switch (action.type) {
        case PAGE.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };
        case PAGE.SET_PAGE_TOP_LOADER:
            return {
                ...state,
                pageTopLoader: action.payload
            };
        case PAGE.SET_DATE_RANGE:
            return {
                ...state,
                dateRange: action.payload
            };
        case PAGE.SET_DATE_RANGE_PICTREE:
            return {
                ...state,
                dateRangePicTree: action.payload
            };
        case PAGE.SET_DATE_RANGE_PICTREE_MIN_MAX:
            return {
                ...state,
                dateRangePicTreeMinMax: action.payload
            };
        case PAGE.SET_DATE_RANGE_PICTREE_NTT:
            return {
                ...state,
                dateRangePicTreeNtt: action.payload
            };
        case PAGE.SET_DATE_RANGE_PICTREE_MISSION:
            return {
                ...state,
                dateRangePicTreeMission: action.payload
            };
        case PAGE.SET_MAU_DATE_RANGE:
            return {
                ...state,
                mauDateRange: action.payload
            };
        case PAGE.SET_MAU_PREV_DATE_RANGE:
            return {
                ...state,
                mauPrevDateRange: action.payload
            };
        case PAGE.SET_WAU_DATE_RANGE:
            return {
                ...state,
                wauDateRange: action.payload
            };
        case PAGE.SET_WAU_PREV_DATE_RANGE:
            return {
                ...state,
                wauPrevDateRange: action.payload
            };
        case PAGE.SET_COMPARISON_DATE_DIFF:
            return {
                ...state,
                comparisonDateDiff: action.payload
            };
        case PAGE.SET_COMPARISON_DATE_DIFF_DISPLAY:
            return {
                ...state,
                comparisonDateDiffDisplay: action.payload
            };
        case PAGE.SET_PREV_DATE_RANGE:
            return {
                ...state,
                prevDateRange: action.payload
            };
        case PAGE.SET_PREV_DATE_RANGE_PICTREE:
            return {
                ...state,
                prevDateRangePicTree: action.payload
            };
        case PAGE.SET_TABLE_ROW_SELECTED:
            return {
                ...state,
                tableRowSelected: action.payload
            };
        case PAGE.SET_ID_TOKEN:
            return {
                ...state,
                idToken: action.payload
            };
        case PAGE.SET_ACCESS_KEY:
            return {
                ...state,
                accessKey: action.payload
            };
        case PAGE.SET_BTN_STATE_DATA_FETCH:
            return {
                ...state,
                btnStateDataFetch: action.payload
            };
        case PAGE.SET_DEP_PRICE:
            return {
                ...state,
                depPrice: action.payload
            };
        case PAGE.SET_CURRENCY:
            return {
                ...state,
                selectedCurrency: action.payload
            };
        case PAGE.SET_FILTER_DRAWER_STATE:
            return {
                ...state,
                filterDrawerState: action.payload
            };
        default:
            return state
    }
}
