import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  Grid,
  Typography,
  Chip,
} from "@material-ui/core";
import ShowChartIcon from '@material-ui/icons/ShowChart';
import TocIcon from '@material-ui/icons/Toc';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import {
  setCurrentPage,
  getInGameItemSalesByOccurrence,
  setInGameItemSalesByOccurrence,
  setPictreeView,
  getNftSalesByOccurrence,
  setNftSalesByOccurrence,
  setNftSalesByOccurrenceType,
  setNftSalesByOccurrenceGame,
} from "actions";
import * as C from "components";
import * as H from "helper";
import * as V from "styles/variables";
import BarStackNormalization from "components/Charts/BarStackNormalization";
import SingleSelect from "components/Select/SingleSelect";
import TableGeneric from "components/Table/TableGeneric";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    lblGameName: {
      color: '#000',
      fontSize: 13,
      minWidth: 160,
    },
    dataCellTd: {
      fontSize: 13,
      paddingRight: 10,
      paddingBottom: 0,
      paddingLeft: 10,
      textAlign: 'right',
      verticalAlign: 'middle',
      borderBottom: '1px solid #eee6e6',
      borderRight: '1px solid #eee6e6',
      height: 45,
    },
    tTdHeader: {
      paddingLeft: '1rem',
      paddingRight: 19,
      fontWeight: 'bold',
      height: 39,
    },
    tTd: {
      height: 44,
      paddingLeft: '1rem',
      borderBottom: '1px solid #eee',
      borderCollapse: 'collapse',
    },
  })
);

const SalesAppearance = ({
  setCurrentPage,
  pageTopLoader,
  getInGameItemSalesByOccurrence,
  setInGameItemSalesByOccurrence,
  setPictreeView,
  pictreeView,
  pictreeChartSize,
  dataByOccurrence,
  getNftSalesByOccurrence,
  setNftSalesByOccurrence,
  setNftSalesByOccurrenceType,
  setNftSalesByOccurrenceGame,
  nftSalesByOccurrence,
}) => {
  const classes = useStyles();
  const [chartState, setChartState] = useState(true);
  const [gameLabel, setGameLabel] = useState("");
  const [chartType, setChartType] = useState({ name: "ストア販売", value: "store" });
  const [seriesData, setSeriesData] = useState([]);
  const [xAxisData, setSAxisData] = useState([]);

  useEffect(() => {
    const game = window.location.pathname.split("/");
    setCurrentPage(window.location.pathname);
    if (window.location.pathname.indexOf("marketplace-sale") !== -1) {
      getNftSalesByOccurrence(game[2]);
      setNftSalesByOccurrenceGame(game[2]);
    } else {
      getInGameItemSalesByOccurrence(game[2]);
    }
    setPictreeView("photo/chart");
  }, []);

  useEffect(() => {
    const game = window.location.pathname.split("/");
    setGameLabel(game[2] === "ingame-sale"
      ? "PlayMining NFT"
      : H.ContentsHelper.getContentsName(game[2])
    );
    setInGameItemSalesByOccurrence([]);
    setNftSalesByOccurrence([]);
    if (window.location.pathname.indexOf("marketplace-sale") !== -1) {
      getNftSalesByOccurrence(game[2]);
      setNftSalesByOccurrenceGame(game[2]);
    } else {
      getInGameItemSalesByOccurrence(game[2]);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (window.location.pathname.indexOf("marketplace-sale") !== -1) {
      if (nftSalesByOccurrence.length > 0) {
        setSeriesData(nftSalesByOccurrence[0]["series"]);
        setSAxisData(nftSalesByOccurrence[0]["date"]);
      }
    } else {
      if (dataByOccurrence.length > 0) {
        setSeriesData(dataByOccurrence[0]["series"]);
        setSAxisData(dataByOccurrence[0]["date"]);
      }
    }
  }, [dataByOccurrence, nftSalesByOccurrence]);

  const handleView = (view) => {
    // setPictreeView(view);
  };

  const handleChange = (event) => {
    setChartType(event.target.value);
    setNftSalesByOccurrenceType(event.target.value);
    setSeriesData([]);
  };

  const getExportData = (data) => {
    let exportData = [];
    if (data.length > 0) {
      let headerArray = ["InGame 購入回数データ"];
      let numOfHeaderData = data[0]["date"].length;
      for (let j = 0; j < numOfHeaderData; j++) {
        headerArray.push(data[0]["date"][j]);
      }

      const countArray = ["初回"].concat(data[0]["series"][0]);
      const count2Array = ["2回目"].concat(data[0]["series"][1]);
      const count3Array = ["3回目"].concat(data[0]["series"][2]);
      const count4Array = ["4回目"].concat(data[0]["series"][3]);
      const count5Array = ["5回目+"].concat(data[0]["series"][4]);
      exportData = [
        headerArray,
        countArray,
        count2Array,
        count3Array,
        count4Array,
        count5Array,
      ]
    }
    return exportData;
  };

  const getTableLabelColumn = () => {
    return (
      <>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>初回</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>2回目</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>3回目</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>4回目</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>5回目+</div>
          </td>
        </tr>
      </>
    );
  };

  const getDataTable = (data) => {
    return (
      <>
        <tr>
          {data[0]["series"][0].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["series"][1].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["series"][2].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["series"][3].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["series"][4].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
      </>
    );
  };

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography variant="body1">{gameLabel}</Typography>
              <Typography variant="body1">
                {window.location.pathname.indexOf("marketplace-sale") !== -1 ? "マケプレ売上" : "InGame 売上"}</Typography>
              <Typography variant="body1">購入回数</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <C.CalendarRangePicker mDate={"2023/06/30"} page="" />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: 16, marginRight: 16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item style={{ marginTop: 0, marginLeft: 48, }}>
          {pictreeView === "photo/chart" &&
           window.location.pathname.indexOf("marketplace-sale") !== -1 &&
            <SingleSelect
              selected={chartType}
              options={[
                { name: "ストア販売", value: "store" },
                { name: "オークション販売", value: "auction" },
                { name: "FIAT販売", value: "fiat" },
              ]}
              handleChange={handleChange}
            />
          }
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={0}
              direction="row"
              style={{ marginTop: 0, marginRight: 16, marginBottom: 8 }}
              justifyContent="flex-end"
              >
              <Grid item></Grid>
              <Grid item className={classes.fillRemailSpace}></Grid>
              <Grid item>
                <Chip avatar={<ShowChartIcon />} label="チャート" onClick={() => handleView("photo/chart")}
                  variant={pictreeView === "photo/chart" ? "default" : "outlined"}
                />
              </Grid>
              <Grid item>
                <Chip avatar={<TocIcon />} label="テーブル" onClick={() => handleView("photo/table")}
                  variant={pictreeView === "photo/table" ? "default" : "outlined"}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {pictreeView === "photo/chart" &&
          <>
          <Grid container spacing={1} className={classes.gridSection}>
            <Grid item xs={12} md={12} lg={12}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : chartState ? (
                xAxisData.length > 0 ? (
                  <BarStackNormalization
                    legend={[
                      "初回" , "2回目", "3回目", "4回目", "5回目+"
                    ]}
                    lblSaveImage={""}
                    xAisData={xAxisData}
                    seriesData={seriesData}
                    chartType={"line"}
                    chartSize={pictreeChartSize}
                  />
                ) : (
                  <div className={classes.selectGameTxt}>
                    <Chip
                      label="No Data"
                      style={{
                        color: V.textColor,
                        backgroundColor: V.placeholderColor,
                      }}
                    />
                  </div>
                )
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.gridSection}>
            <Grid item xs={12} md={12} lg={12}>
              <TableGeneric
                game={gameLabel}
                dataLabel={[]}
                data={window.location.pathname.indexOf("marketplace-sale") !== -1 ? nftSalesByOccurrence : dataByOccurrence}
                tableKey={[]}
                csvFileName={`NFT購入回数データ - ${gameLabel}`}
                getExportData={getExportData}
                getDataTable={getDataTable}
                getTableLabelColumn={getTableLabelColumn}
              />
            </Grid>
          </Grid>
          </>
        }
      </C.ContentWrapper>
    </Fragment>
  );
};

SalesAppearance.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  pictreeView: state.pictree.pictreeView,
  pictreeChartSize: state.pictree.chartType,
  dataByOccurrence: state.inGameItemSales.dataByOccurrence,
  nftSalesByOccurrence: state.nftSales.nftSalesByOccurrence,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getInGameItemSalesByOccurrence: (param) => dispatch(getInGameItemSalesByOccurrence(param)),
  setInGameItemSalesByOccurrence: (param) => dispatch(setInGameItemSalesByOccurrence(param)),
  setPictreeView: (param) => dispatch(setPictreeView(param)),
  getNftSalesByOccurrence: (param) => dispatch(getNftSalesByOccurrence(param)),
  setNftSalesByOccurrence: (param) => dispatch(setNftSalesByOccurrence(param)),
  setNftSalesByOccurrenceType: (param) => dispatch(setNftSalesByOccurrenceType(param)),
  setNftSalesByOccurrenceGame: (param) => dispatch(setNftSalesByOccurrenceGame(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(SalesAppearance));
