import React, { useState, useEffect, useRef } from "react";
import _ from 'lodash';
import moment from "moment";
import { CSVLink } from "react-csv";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress, Checkbox, TextField, Button, Grid, Chip, Box, Collapse, IconButton, Typography, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Autocomplete, Pagination } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import GetAppIcon from '@material-ui/icons/GetApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setCurrentPage, setTxHistorySearchTerm, getNftAssetSearch, getNftAssetHistoryData, setTxHistoryDataFilter, setFilterDrawerState, setPageNumber,
    getNftAssetHistoryDataByTerm, getNftTxCsvData, setNftTxCsvData, setNftTxCsvDownloader } from 'actions';
import LocalCalendar from 'components/Calendar/LocalCalendar';
import * as C from 'components';
import * as V from 'styles/variables';
import * as H from 'helper';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function setChipLabel (label, classes) {
    const colorKeyName = 'chipColor'+label.toLowerCase().replace(/[^A-Z0-9]+/ig, "");
    const newLabel = label === "NFT:DEP" ? "PlayMining NFT" : label;
    return <Chip
                className={classes[colorKeyName]}
                size="small"
                label={newLabel}
        />
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell align="center">
            <img className={classes.thumb} src={row.img_src} width="100" />
          </TableCell>
          <TableCell align="left" component="th" scope="row">
            {row.name}<br />{row.content != undefined ? setChipLabel(row.content, classes) : ""}
          </TableCell>
          <TableCell align="center">{row.asset_id}<br />{row.token_id}</TableCell>
          <TableCell align="right">{row.price}</TableCell>
          <TableCell align="right">{row.type}</TableCell>
          <TableCell align="right">
            &nbsp;{row.from}<br />
            &rarr;&nbsp;{row.to === "n/a" ? "運営" : row.to}</TableCell>
          <TableCell align="center">
            {row.from_country !== null && row.from_country !== undefined && row.from_country !== "n/a" &&
            <img src={H.CountryHelper.getCountryCode(row.from_country)} />
            }
            {row.from_country === "n/a" &&
            "n/a"
            }<br />
            {row.to_country !== null && row.to_country !== 'n/a' &&
            <img src={H.CountryHelper.getCountryCode(row.to_country)} />
            }
          </TableCell>
          <TableCell align="right">{row.created_at}</TableCell>
          {/* <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell> */}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            {/* <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Total price ($)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.history.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.date}
                        </TableCell>
                        <TableCell>{historyRow.customerId}</TableCell>
                        <TableCell align="right">{historyRow.amount}</TableCell>
                        <TableCell align="right">
                          {Math.round(historyRow.amount * row.price * 100) / 100}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse> */}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
}

const NftDepTxHistory = ({
    setCurrentPage,
    currentPage,
    setTxHistorySearchTerm,
    assetOption,
    getNftAssetHistoryData,
    txHistoryData,
    txHistoryStatData,
    txHistoryDataFilter,
    setTxHistoryDataFilter,
    pageTopLoader,
    setFilterDrawerState,
    filterDrawerState,
    assetSearchTerm,
    setPageNumber,
    pageNumber,
    getNftAssetHistoryDataByTerm,
    getNftTxCsvData,
    setNftTxCsvData,
    nftTxCsvData,
    nftTxCsvLoader,
}) => {
    const classes = useStyles();
    const [btnStateDataFetch, setBtnState] = useState(true);
    const [assetOptions, setAssetOptions] = useState(assetOption);
    const [assetName, setAssetName] = useState({name:""});
    const [txRowHisotryData, setTxRowHisotryData] = useState([]);
    const [noData, setNodata] = useState(false);
    const [defaultDateRange, setDefaultDateRagne] = useState([
        moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY/MM/DD'),
        moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD')
    ]);
    //const [pageNumber, setPageNumber] = useState(1);
    const [totalPageNum, setTotalPageNum] = useState(0);
    const csvLinkRef = useRef(null);

    const handleChangeOption = (data) => {
        // let optionSelectedArray = [];
        // _.forEach(data, function(item, i) {
        //     optionSelectedArray.push(item.value)
        // });
        // setNftDepTrendOptionSelected(data);
        setBtnState(false);
    }

    useEffect(() => {
        setCurrentPage(window.location.pathname);
        getNftAssetHistoryData(pageNumber);
        setNftTxCsvData([]);
    }, []);

    useEffect(() => {
        if (nftTxCsvData.length > 0) {
            csvLinkRef.current.link.click();
            setNftTxCsvData([]);
        }
    }, [nftTxCsvData]);

    useEffect(() => {
        setAssetOptions(assetOption);
    }, [assetOption]);

    useEffect(() => {
        const pageCount = txHistoryStatData[0]['totalTx'] / 20;
        setTotalPageNum(Math.ceil(pageCount));
    }, [txHistoryStatData]);

    useEffect(() => {
        if (txHistoryData.length > 0) {
            let dataRows = [];
            _.each(txHistoryData, function(dataRow) {
                const dateTimeUtc = moment.tz(dataRow[9]["stringValue"], 'UTC');
                const dateTimeSgt = moment(dateTimeUtc).tz('Asia/Singapore').format('YYYY/MM/DD HH:mm:ss');
                let rowObj = {
                    "img_src": dataRow[1]["stringValue"],
                    "name": dataRow[0]["stringValue"],
                    "asset_id": dataRow[2]["stringValue"],
                    "token_id": dataRow[3]["stringValue"],
                    "price": dataRow[6]["stringValue"] === 'CREDIT_PAYMENT_MARKET' ?
                        `USD ${Number(dataRow[12]["stringValue"])} = DEP ${Number(dataRow[4]["longValue"])}`
                        : Number(dataRow[4]["longValue"]),
                    "type": dataRow[6]["stringValue"] === 'TRADE_2ND' ? "2次" : dataRow[6]["stringValue"] === 'CREDIT_PAYMENT_MARKET' ? "Fiat" : "1次",
                    "from": dataRow[6]["stringValue"] === 'TRADE_2ND' && dataRow[7]["stringValue"] == "n/a" ? "SYSTEM (BLIND AUCTION)" : dataRow[7]["stringValue"],
                    "from_country": dataRow[10]["stringValue"],
                    "to": dataRow[8]["stringValue"],
                    "to_country": dataRow[11]["stringValue"],
                    "content": H.ContentsHelper.getContentsNameById(dataRow[5]['stringValue']),
                    "created_at": dataRow[9]["stringValue"],
                }
                dataRows.push(rowObj);
                rowObj = {};
            });
            setTxRowHisotryData(dataRows);
            setNodata(false);
        } else {
            setNodata(true);
        }
    }, [txHistoryData]);

    const handlePageChange = (event, value) => {
        setPageNumber(value);
        if (assetSearchTerm.length > 0 &&
            assetSearchTerm[0]['value'] !== "") {
            getNftAssetHistoryDataByTerm();
        } else {
            getNftAssetHistoryData(value);
        }
    };

    const setRange = (newValue) => {
        const startDate = moment(newValue[0]).format('YYYY-MM-DD');
        const endDate = moment(newValue[1]).format('YYYY-MM-DD');
        let newFilter = [ ... txHistoryDataFilter ];
        newFilter[0]['dateRange'] = [startDate, endDate];
        setTxHistoryDataFilter(newFilter);
        setBtnState(false);
    }

    const showData = (value) => {
        if (value === "--") {
            return value;
        }
        if (isNaN(value)) {
            return 0;
        }
        return H.FormatNumStrHelper.formatCommaThousand(value);
    }

    const fetchData = () => {
        if (assetSearchTerm.length > 0 &&
            assetSearchTerm[0]['value'] !== "") {
            getNftAssetHistoryDataByTerm();
        } else {
            getNftAssetHistoryData(pageNumber);
        }
        setBtnState(!btnStateDataFetch);
    }

    const handleInputChange = (data, type) => {
        switch(type) {
            case "asset_name":
                setAssetName({name:data});
                break;
            default:
        }
        const termObject = {
            type: type,
            value: data.replace(/\s\s+/g, '\ ')
        }
        let cloneTerm = [...assetSearchTerm];

        const objIndex = cloneTerm.findIndex((termObject => termObject.type == type));
        if (objIndex === -1) {
            cloneTerm.push(termObject);
        } else {
            cloneTerm[objIndex].value = data;
        }
        setTxHistorySearchTerm(cloneTerm);
    }

    const getMinDate = () => {
        let minDate = null;
        if (currentPage.indexOf("graffitiracer") !== -1) {
            minDate =  "2022/10/31";
        }
        return minDate;
    }

    return (
        <C.ContentWrapper>
            <Grid container spacing={0} className={classes.gridSection} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12} lg={10}>

                <Grid container spacing={1} className={classes.gridSection} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Autocomplete
                            //multiple
                            freeSolo
                            disablePortal
                            limitTags={3}
                            size="small"
                            id="select-game"
                            value={assetName}
                            options={assetOptions}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                                </React.Fragment>
                            )}
                            onChange={(e, newValue) => {
                                handleChangeOption(newValue);
                            }}
                            onInputChange={(e, newValue) => {
                                handleInputChange(newValue, 'asset_name');
                            }}
                            style={{ minWidth: 400 }}
                            renderInput={(params) => (
                                <TextField {...params} label="アセット名で検索" />
                            )}
                            className={classes.autoSelect}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <LocalCalendar
                                    setRange={setRange}
                                    setBtnState={setBtnState}
                                    defaultRange={defaultDateRange}
                                    newRange={[
                                        txHistoryDataFilter[0]['dateRange'][0],
                                        txHistoryDataFilter[0]['dateRange'][1],
                                    ]}
                                    mDate={getMinDate()}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    //disabled={btnStateDataFetch}
                                    className={classes.dataBtn}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => fetchData()}>
                                    GO
                                </Button>
                            </Grid>
                            {/* <Grid item>
                                <Button
                                    //disabled={btnStateDataFetch}
                                    className={classes.filterBtn}
                                    variant="contained"
                                    color="default"
                                    onClick={() => setFilterDrawerState(!filterDrawerState ? currentPage : false )}>
                                    FILTER
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>

                </Grid>
            </Grid>

            <Grid container spacing={0} className={classes.gridSection} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12} lg={10}>
                    <Paper className={classes.statsSection}>
                        <Grid container direction="row" className={classes.gridSection} justifyContent="flex-start">
                            <Grid className={classes.statBox} item xs={12} sm={6} md={4} lg={2}>
                                <Grid container direction="row" className={classes.gridSection} justifyContent="space-between">
                                    <Grid item>
                                        <div className={classes.statTitle}>合計件数:</div>
                                    </Grid>
                                    <Grid item>
                                        {nftTxCsvLoader &&
                                            <CircularProgress className={classes.titleDl} size={20} />
                                        }
                                        {!nftTxCsvLoader &&
                                            <>
                                            <IconButton onClick={() => getNftTxCsvData()} className={classes.titleDl} size="small">
                                                <GetAppIcon />
                                            </IconButton>
                                            </>
                                        }
                                        <CSVLink
                                            data={nftTxCsvData}
                                            filename='NFT取引履歴データ.csv'
                                            className='hidden'
                                            ref={csvLinkRef}
                                            target='_blank'
                                        />
                                    </Grid>
                                </Grid>
                                <div className={classes.statValue}>{showData(txHistoryStatData[0]['totalTx'])}</div>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6}  md={4} lg={2}>
                                <div className={classes.statTitle}>DEP総額:</div>
                                <div className={classes.statValue}>{showData(txHistoryStatData[0]['totalDep'])}</div>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6}  md={4} lg={2}>
                                <Grid container direction="row" justifyContent="space-evenly">
                                    <Grid item xs={6}>
                                        <div className={classes.statTitle}>1次DEP件数:</div>
                                        <div className={classes.statValue}>{showData(txHistoryStatData[0]['totalPrimeTx'])}</div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.statTitle}>1次Fiat件数:</div>
                                        <div className={classes.statValue}>{showData(txHistoryStatData[0]['totalFiatTx'])}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6}  md={4} lg={2}>
                                <div className={classes.statTitle}>2次件数:</div>
                                <div className={classes.statValue}>{showData(txHistoryStatData[0]['totalSecondTx'])}</div>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6}  md={4} lg={2}>
                                <Grid container direction="row" justifyContent="space-evenly">
                                    <Grid item xs={6}>
                                        <div className={classes.statTitle}>1次DEP総額:</div>
                                        <div className={classes.statValue}>{showData(txHistoryStatData[0]['totalPrimeDep'])}</div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.statTitle}>1次Fiat総額:</div>
                                        <div className={classes.statValue}>US$ {showData(txHistoryStatData[0]['totalFiatAmount'])}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6} md={4} lg={2}>
                                <div className={classes.statTitle}>2次DEP総額:</div>
                                <div className={classes.statValue}>{showData(txHistoryStatData[0]['totalSecondDep'])}</div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={1} className={classes.gridSection} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12} lg={10}>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table stickyHeader aria-label="collapsible table" className={classes.table}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="left">ASSET 名</TableCell>
                                    <TableCell align="center">ASSET ID<br />TOKEN ID</TableCell>
                                    <TableCell align="center">DEP</TableCell>
                                    <TableCell align="center">TYPE</TableCell>
                                    <TableCell align="center">購入者PMID<br />移転先PMID</TableCell>
                                    <TableCell align="center">購入者国<br />移転先国</TableCell>
                                    <TableCell align="center">日時</TableCell>
                                    {/* <TableCell /> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {pageTopLoader && (
                                <TableRow className={classes.dataLoadingTxt} >
                                    <TableCell align="center" colSpan={7}>Loading...</TableCell>
                                </TableRow>
                            )}
                            {!noData && txRowHisotryData.map((row, index) => (
                                <Row key={index} row={row} />
                            ))}
                            {!pageTopLoader && noData && (
                                <TableRow className={classes.dataLoadingTxt} >
                                    <TableCell align="center" colSpan={7}>No Data</TableCell>
                                </TableRow>
                            )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {totalPageNum > 0 &&
            <Grid container spacing={1} className={classes.pageSection} alignItems="center" justifyContent="center">
                <Pagination count={totalPageNum} page={pageNumber} onChange={handlePageChange} />
            </Grid>
            }
        </C.ContentWrapper>
    );
};

NftDepTxHistory.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    assetSearchLoading: state.nftDepTxHisotry.assetSearchLoading,
    assetOption: state.nftDepTxHisotry.assetOption,
    txHistoryData: state.nftDepTxHisotry.txHistoryData,
    txHistoryStatData: state.nftDepTxHisotry.txHistoryStatData,
    txHistoryDataFilter: state.nftDepTxHisotry.txHistoryDataFilter,
    pageTopLoader: state.page.pageTopLoader,
    filterDrawerState: state.page.filterDrawerState,
    currentPage: state.page.currentPage,
    assetSearchTerm: state.nftDepTxHisotry.assetSearchTerm,
    pageNumber: state.nftDepTxHisotry.pageNumber,
    nftTxCsvData: state.nftDepTxHisotry.nftTxCsvData,
    nftTxCsvLoader: state.nftDepTxHisotry.nftTxCsvLoader,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    setTxHistorySearchTerm: (param) => dispatch(setTxHistorySearchTerm(param)),
    getNftAssetHistoryData: (param) => dispatch(getNftAssetHistoryData(param)),
    setTxHistoryDataFilter: (param) => dispatch(setTxHistoryDataFilter(param)),
    setFilterDrawerState: (param) => dispatch(setFilterDrawerState(param)),
    setPageNumber: (param) => dispatch(setPageNumber(param)),
    getNftAssetHistoryDataByTerm: () => dispatch(getNftAssetHistoryDataByTerm()),
    getNftTxCsvData: () => dispatch(getNftTxCsvData()),
    setNftTxCsvData: (param) => dispatch(setNftTxCsvData(param)),
    setNftTxCsvDownloader: (param) => dispatch(setNftTxCsvDownloader(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(NftDepTxHistory));

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& > *': {
              //borderBottom: 'unset',
            },
        },
        dataBtn: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
            color: V.btnLabelColor,
        },
        autoSelect: {
            marginTop: 8,
            marginLeft: 16,
        },
        gridSection: {
            marginTop: theme.spacing(1),
        },
        pageSection: {
            marginTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        table: {
            minHeight: "500px",
            overflowY: "scroll"
        },
        dataLoadingTxt: {
            // marginTop: theme.spacing(4),
            // marginLeft: theme.spacing(4),
        },
        statsSection: {
            minHeight: 60,
            padding: theme.spacing(2),
            borderRadius: 4,
        },
        statBox: {
            marginTop: 0,
            minHeight: 60,
            paddingLeft: 8,
            borderRight: '1px solid #dcdcdc',
            "&:last-child": {
                borderRight: 'none',
            },
        },
        statTitle: {
            fontSize: 13,
        },
        statValue: {
            fontSize: 15,
            paddingTop: 8,
            fontWeight: 500,
        },
        filterBtn: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
        },
        chipColorjobtribes: {
            marginTop: theme.spacing(2),
            backgroundColor: V.jobtribes,
            color: '#fff',
        },
        chipColorpuzzle: {
            marginTop: theme.spacing(2),
            backgroundColor: V.puzzle,
            color: '#fff',
        },
        chipColorluckyfarmer: {
            marginTop: theme.spacing(2),
            backgroundColor: V.luckyfarmer,
            color: '#fff',
        },
        chipColorpmportal: {
            marginTop: theme.spacing(2),
            backgroundColor: V.pmportal,
            color: '#fff',
        },
        chipColornftdep: {
            marginTop: theme.spacing(2),
            backgroundColor: V.nftdep,
            color: '#fff',
        },
        chipColorcookinburger: {
            marginTop: theme.spacing(2),
            backgroundColor: V.cookinburger,
            color: '#fff',
        },
        chipColorgraffitiracer: {
            marginTop: theme.spacing(2),
            backgroundColor: V.graffitiracer,
            color: '#fff',
        },
        chipColordragonramen: {
            marginTop: theme.spacing(2),
            backgroundColor: V.dragonramen,
            color: '#fff',
        },
        chipColorlostarchive: {
          marginTop: theme.spacing(2),
          backgroundColor: V.lostarchvie,
          color: '#193a3a',
        },
        chipColorsoulfusers: {
            marginTop: theme.spacing(2),
            backgroundColor: V.soulfusers,
            color: '#fff',
        },
        chipColorroguerollrulers: {
            marginTop: theme.spacing(2),
            backgroundColor: V.roguerollrulers,
            color: '#fff',
        },
        chipColorbouncybunny: {
            marginTop: theme.spacing(2),
            backgroundColor: V.bouncybunny,
            color: '#000',
        },
        titleDl: {
            marginRight: 16,
            color: "#000",
        },
        thumb: {
            borderRadius: 8
        }
    }),
);
