import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { GetApp } from '@material-ui/icons';
import { CSVLink } from "react-csv";
import Label from 'utils/Label';
import * as H from 'helper';
import * as C from 'components';

const JtcbNftTrendTable = ({
  trendDataLoading,
  title,
  dataCount,
  dataAmount,
}) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // combine data
    const dataCombined = dataCount.concat(dataAmount);
    setTableData(dataCombined);
  }, [dataCount, dataAmount]);

  const getTableColumnName = (tableData) => {
    return  <Fragment>
              {tableData.map((d, i) =>
              <tr key={i}>
                <td className={classes.tTd}>
                  <div className={classes.lblGameName}>{Label.chartSeriesName[d.id]}</div>
                  <div className={classes.lblUniqueUser}></div>
                </td>
              </tr >
              )}
            </Fragment>
  }

  const getDataHeader = (dataCount) => {
    return <Fragment>
            <tr className={classes._GAIv}>
            {dataCount[0]['date'].map((item, i) => (
                <td className={classes.dataHeaderTd} key={i}>{moment(item).format('M/D')}</td>
            ))}
            </tr >
          </Fragment>
  }

  const getDataTable = (dataCount) => {
    let dataAdjusted = [];
    _.forEach(dataCount, function(value) {
        dataAdjusted.push(value.data);
    });

    const getDataNumUsers = (numUserData, allDataArray, index) => {
      const dataPassed = Number(numUserData);
      return dataPassed !== 0 ? H.FormatNumStrHelper.formatCommaThousand(numUserData)
                              : index === allDataArray.indexOf(0)
                                  ? ''
                                  : '';
    }

    return  <Fragment>
              {dataAdjusted.map((d, i) =>
              <tr key={i}>
                {d.map((k, j) =>
                  <td className={classes.dataCellTd} key={j}>
                  {getDataNumUsers(k, d, j)}
                  </td>
                )}
              </tr >
              )}
            </Fragment>
  }

  const getExportData = () => {
    let exportData = [];
    if (dataCount && dataCount.length > 0) {
      let headerArray = ["日付"];
      let numOfHeaderData = dataCount[0]['date'].length;
      for (let j=0; j<numOfHeaderData; j++) {
        headerArray.push(dataCount[0]['date'][j]);
      }
      exportData.push(headerArray);
      for (let i=0; i<dataCount.length; i++) {
        let eachDataArray = [H.FormatNumStrHelper.formatTransactionTypeToName(dataCount[i]['id'])];
        for (let k=0; k<dataCount[i]['data'].length; k++) {
          eachDataArray.push(dataCount[i]['data'][k]);
        }
        exportData.push(eachDataArray);
        eachDataArray = [];
      }
      for (let i=0; i<dataAmount.length; i++) {
        let eachDataArray2 = [H.FormatNumStrHelper.formatTransactionTypeToName(dataAmount[i]['id'])];
        for (let k=0; k<dataAmount[i]['data'].length; k++) {
          eachDataArray2.push(dataAmount[i]['data'][k]);
        }
        exportData.push(eachDataArray2);
        eachDataArray2 = [];
      }
    }
    return exportData;
  }

  return (
    <Paper>
      <div className={classes.titleContainer}>
        <div className={classes.widgetTitle}>{title}</div>
        <div className={classes.exportBtnContainer}>
          <CSVLink
              data={getExportData()}
              filename={`${title}データ`}
          >
            <Button
                variant="contained"
                color="default"
                className={classes.exportBtn}
                startIcon={<GetApp />}
            >
                CSV出力
            </Button>
          </CSVLink>
        </div>
      </div>
      <div className={classes.tableContainer}>
      {trendDataLoading
        ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div>
        : tableData.length > 0
          ?
            <Fragment>
              <div className={classes.dateTableWrapper}>
              <table className={classes.leftTable}>
                  <tbody>
                  <tr>
                      <td className={classes.tTdHeader}>
                      </td>
                  </tr >
                  {getTableColumnName(tableData)}
                  </tbody>
              </table>
              </div>

              <div className={classes.dataTableWrapper}>
              <table className={classes.dataTableBody}>
              <tbody>
                  {getDataHeader(tableData)}
                  {getDataTable(tableData)}
                  </tbody>
              </table>
              </div>
            </Fragment>
          : <div className={classes.loaderBox}><C.NoData text="No Data" /></div>
        }
      </div>
    </Paper>
  )
}

JtcbNftTrendTable.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => ({
  trendDataLoading: state.jtcbNftTrend.jtcbNftTrendDateLoading,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(JtcbNftTrendTable);

const useStyles = makeStyles({
  loaderBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderBarImg: {
    width: 40,
  },
  tableContainer: {
    display: 'flex',
    minHeight: 200,
  },
  dateTableWrapper: {
    float: 'left',
  },
  dataTableWrapper: {
    float: 'left',
    overflow: 'scroll',
  },
  leftTable: {
    border: '1px solid #eee',
  },
  lblGameName: {
    color: '#000',
    fontSize: 13,
    minWidth: 160,
  },
  lblUniqueUser: {
    color: '#6f6969',
    fontSize: 11,
    minWidth: 180,
  },
  _GAIv: {
    height: 40,
    backgroundColor: "#4#4#4",
    borderLeft: "1px solid #eee6e6",
    color: "#666",
    fontSize: 11,
    minWidth: 80,
    paddingRight: 10,
    textAlign: 'right',
  },
  dataHeaderTd: {
    width: 100,
    paddingRight: 10,
    borderBottom: '1px solid #eee6e6',
  },
  dataTableBody: {
    fontSize: 11,
    paddingRight: 10,
    paddingBottom: 0,
    textAlign: 'right',
    verticalAlign: 'middle',
    border: '1px solid #eee6e6',
    height: '3em',
    overflowX: 'auto',
  },
  dataCellTd: {
    fontSize: 13,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
    textAlign: 'right',
    verticalAlign: 'middle',
    borderBottom: '1px solid #eee6e6',
    borderRight: '1px solid #eee6e6',
    height: 45,
  },
  tTdHeader: {
    paddingLeft: '1rem',
    paddingRight: 19,
    fontWeight: 'bold',
    height: 39,
  },
  tTd: {
    height: 44,
    paddingLeft: '1rem',
    borderBottom: '1px solid #eee',
    borderCollapse: 'collapse',
  },
  tableNumberData: {
    fontSize: 13,
    paddingBottom: 4,
  },
  exportBtn: {
    marginLeft: 16,
  },
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  widgetTitle: {
    flexBasis: '50%',
    fontSize: 16,
    padding: 16,
  },
  exportBtnContainer: {
    marginLeft: 'auto',
    padding: 16,
  }
});
