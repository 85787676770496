import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { GetApp } from '@material-ui/icons';
import { CSVLink } from "react-csv";
import * as H from 'helper';
import * as C from 'components';

const ActiveUsersTable = ({
  activeUsersGameSelected,
  activeUsersSizeSelected,
  activeUsersData,
  pageTopLoader,
}) => {
  const classes = useStyles();

//   const getTotalUserInRange = (cohortRangeData) => {
//     let totalUser = cohortRangeData.map((data, i) =>
//       i > 0 ? Number(data[1]) : 0
//     );
//     return H.FormatNumStrHelper.formatCommaThousand(_.sum(totalUser));
//   }

  const getGameNameTable = (activeUsersGameSelected) => {
    return  <Fragment>
              {activeUsersGameSelected.map((d, i) =>
              <tr key={i}>
                <td className={classes.tTd}>
                  <div className={classes.lblGameName}>{d.name}</div>
                  <div className={classes.lblUniqueUser}></div>
                </td>
              </tr >
              )}
            </Fragment>
  }

  const getDataHeader = (size, activeUsersData) => {
    return <Fragment>
            <tr className={classes._GAIv}>
            {activeUsersData[0]['date'].map((item, i) => (
                <td className={classes.dataHeaderTd} key={i}>{size.value === 'dau' ? moment(item).format('M/D') : item}</td>
            ))}
            </tr >
          </Fragment>
  }

  const getDataPercent = (cellData, allDataArray, index) => {
    return cellData !== 0 ? (cellData / allDataArray[0] * 100).toFixed(2)+'%' : '';
  }

  const getPercentColour = (cellData, allDataArray, index) => {
    if (cellData === 0) {
      return null;
    }
    if (index === 0) {
      return { backgroundColor: "#11643F", color: "#FFF" }
    } else {
      const percentData = (cellData / allDataArray[0] * 100).toFixed(2);
      if (percentData > 90) {
        return { backgroundColor: "#11643F", color: "#FFF" }
      } else if (percentData <= 90 && percentData > 80) {
        return { backgroundColor: "#2C7554", color: "#FFF" }
      } else if (percentData <= 80 && percentData > 70) {
        return { backgroundColor: "#46876A", color: "#FFF" }
      } else if (percentData <= 70 && percentData > 60) {
        return { backgroundColor: "#548F75", color: "#FFF" }
      } else if (percentData <= 60 && percentData > 50) {
        return { backgroundColor: "#61987F", color: "#FFF" }
      } else if (percentData <= 50 && percentData > 40) {
        return { backgroundColor: "#89B29F", color: "#FFF" }
      } else if (percentData <= 40 && percentData > 30) {
        return { backgroundColor: "#A4C3B5", color: "#434343" }
      } else if (percentData <= 30 && percentData > 20) {
        return { backgroundColor: "#BFD4CA", color: "#434343" }
      } else if (percentData <= 20 && percentData > 10) {
        return { backgroundColor: "#D9E6E0", color: "#434343" }
      } else if (percentData <= 10 && percentData > 0) {
        return { backgroundColor: "#E7EEEA", color: "#434343" }
      }
    }
  }

  const getDataTable = (activeUsersData) => {
    let dataAdjusted = [];
    _.forEach(activeUsersData, function(value) {
        dataAdjusted.push(value.data);
    });

    const getDataNumUsers = (numUserData, allDataArray, index) => {
      const dataPassed = Number(numUserData);
      return dataPassed !== 0 ? H.FormatNumStrHelper.formatCommaThousand(numUserData)
                              : index === allDataArray.indexOf(0)
                                  ? ''
                                  : '';
    }

    return  <Fragment>
              {dataAdjusted.map((d, i) =>
              <tr key={i}>
                {d.map((k, j) =>
                  <td /*style={getPercentColour(d[j], d, j)}*/ className={classes.dataCellTd} key={j}>
                  {/* <span className={classes.tableNumberData}>{getDataNumUsers(d[j], d, j)}</span><br />
                  {getDataPercent(d[j], d, j)} */}
                  {getDataNumUsers(k, d, j)}
                  </td>
                )}
              </tr >
              )}
            </Fragment>
  }

  const getActiveUsersData = () => {
    let exportData = [];
    if (activeUsersData && activeUsersData[0]['data'].length > 0) {
      let headerArray = ["GAME"];
      let numOfHeaderData = activeUsersData[0]['date'].length;
      for (let j=0; j<numOfHeaderData; j++) {
        headerArray.push(activeUsersData[0]['date'][j]);
      }
      exportData.push(headerArray);
      for (let i=0; i<activeUsersData.length; i++) {
        let eachDataArray = [H.FormatNumStrHelper.formatGameKeyToName(activeUsersData[i]['id'])];
        for (let k=0; k<activeUsersData[i]['data'].length; k++) {
          eachDataArray.push(activeUsersData[i]['data'][k]);
        }
        exportData.push(eachDataArray);
        eachDataArray = [];
      }
    }
    return exportData;
  }

  return (
    <Paper>
      <div className={classes.titleContainer}>
        <div className={classes.widgetTitle}>ゲーム別アクティブユーザー :</div>
        <div className={classes.exportBtnContainer}>
          <CSVLink
              data={getActiveUsersData()}
              filename={"Active Users Data "+activeUsersSizeSelected.name}
          >
            <Button
                variant="contained"
                color="default"
                className={classes.exportBtn}
                startIcon={<GetApp />}
            >
                CSV出力
            </Button>
          </CSVLink>
        </div>
      </div>
      <div className={classes.tableContainer}>
      {pageTopLoader
        ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div>
        : activeUsersGameSelected.length > 0 ?
              activeUsersData[0]['data'].length > 0 ?
              <Fragment>
                    <div className={classes.dateTableWrapper}>
                    <table className={classes.leftTable}>
                        <tbody>
                        <tr>
                            <td className={classes.tTdHeader}>
                            </td>
                        </tr >
                        {getGameNameTable(activeUsersGameSelected)}
                        </tbody>
                    </table>
                    </div>

                    <div className={classes.dataTableWrapper}>
                    <table className={classes.dataTableBody}>
                    <tbody>
                        {getDataHeader(activeUsersSizeSelected, activeUsersData)}
                        {getDataTable(activeUsersData)}
                        </tbody>
                    </table>
                    </div>
                </Fragment>
              : <div className={classes.loaderBox}><C.NoData text="No Data" /></div>
            : <div className={classes.loaderBox}><C.NoData text="ゲームとサイズを選択してください" /></div>
        }
      </div>
    </Paper>
  )
}

ActiveUsersTable.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
  activeUsersGameSelected: state.activeUsers.activeUsersGameSelected,
  activeUsersSizeSelected: state.activeUsers.activeUsersSizeSelected,
  activeUsersData: state.activeUsers.activeUsersData,
  pageTopLoader: state.page.pageTopLoader,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveUsersTable);

const useStyles = makeStyles({
  loaderBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderBarImg: {
    width: 40,
  },
  tableContainer: {
    display: 'flex',
    minHeight: 200,
  },
  dateTableWrapper: {
    float: 'left',
  },
  dataTableWrapper: {
    float: 'left',
    overflow: 'scroll',
  },
  leftTable: {
    border: '1px solid #eee',
  },
  lblGameName: {
    color: '#000',
    fontSize: 13,
    minWidth: 160,
  },
  lblUniqueUser: {
    color: '#6f6969',
    fontSize: 11,
    minWidth: 180,
  },
  _GAIv: {
    height: 40,
    backgroundColor: "#4#4#4",
    borderLeft: "1px solid #eee6e6",
    color: "#666",
    fontSize: 11,
    minWidth: 80,
    paddingRight: 10,
    textAlign: 'right',
  },
  dataHeaderTd: {
    width: 100,
    paddingRight: 10,
    borderBottom: '1px solid #eee6e6',
  },
  dataTableBody: {
    fontSize: 11,
    paddingRight: 10,
    paddingBottom: 0,
    textAlign: 'right',
    verticalAlign: 'middle',
    border: '1px solid #eee6e6',
    height: '3em',
    overflowX: 'auto',
  },
  dataCellTd: {
    fontSize: 13,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
    textAlign: 'right',
    verticalAlign: 'middle',
    borderBottom: '1px solid #eee6e6',
    borderRight: '1px solid #eee6e6',
    height: 45,
  },
  tTdHeader: {
    paddingLeft: '1rem',
    paddingRight: 19,
    fontWeight: 'bold',
    height: 39,
  },
  tTd: {
    height: 44,
    paddingLeft: '1rem',
    borderBottom: '1px solid #eee',
    borderCollapse: 'collapse',
  },
  tableNumberData: {
    fontSize: 13,
    paddingBottom: 4,
  },
  exportBtn: {
    marginLeft: 16,
  },
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  widgetTitle: {
    flexBasis: '50%',
    fontSize: 16,
    padding: 16,
  },
  exportBtnContainer: {
    marginLeft: 'auto',
    padding: 16,
  }
});