import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Box, LinearProgress, Typography } from '@material-ui/core';
import StatBoxSlim from "components/Stats/StatBoxSlim";

const useStyles = makeStyles((theme) =>
  createStyles({
    lblPercent: {
      marginTop: 32,
      flexGrow: 1,
      textAlign: "right",
      fontSize: 13,
    },
    tooltip: {
      position: 'absolute',
      top: '8px',
      left: '50%',
      transform: 'translateX(-50%)',
      padding: '5px 10px',
      borderRadius: '4px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
    },
  })
);

const PhotoShoot = ({
  dateRange,
  homeDataPhotoShoot,
  homeDataPhotoShootBySeason,
  selectedSeason,
  seasonData,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item m={12} md={8} lg={10}>
        <Grid container spacing={1}>
          <Grid item sm={12} md={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3}>
                <StatBoxSlim
                  title="総写真数"
                  value={homeDataPhotoShoot.countPic !== undefined ? homeDataPhotoShoot.countPic  : "-"}
                  // prevValue={homeDataPhotoShoot.countPicPrev !== undefined ? homeDataPhotoShoot.countPicPrev  : "-"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatBoxSlim
                  title="総基数"
                  value={homeDataPhotoShootBySeason.totalSum !== undefined ? homeDataPhotoShootBySeason.totalSum  : "-"}
                  // prevValue={homeDataPhotoShoot.countAssetPrev !== undefined ? homeDataPhotoShoot.countAssetPrev  : "-"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatBoxSlim
                  title="電柱基数"
                  value={homeDataPhotoShootBySeason.totalCounts !== undefined ? homeDataPhotoShootBySeason.totalCounts.denchu  : "-"}
                  // prevValue={homeDataPhotoShoot.countDenchuPrev !== undefined ? homeDataPhotoShoot.countDenchuPrev  : "-"}
                  vsExtra={homeDataPhotoShoot.countDupeDenchu !== undefined
                    ? `(重複数: ${selectedSeason.length > 1 ? "n/a" : homeDataPhotoShoot.countDupeDenchu})`
                    : null}
                  tip="ユニークの電柱基数"
                  valueExtra={`達成率: ${
                    homeDataPhotoShootBySeason.totalRatio !== undefined
                    ? `${homeDataPhotoShootBySeason.totalRatio.denchu}%`
                    : "--"}`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatBoxSlim
                  title="換気口基数"
                  value={homeDataPhotoShootBySeason.totalCounts !== undefined ? homeDataPhotoShootBySeason.totalCounts.kankiko : "-"}
                  // prevValue={homeDataPhotoShoot.countKankikoPrev !== undefined ? homeDataPhotoShoot.countKankikoPrev  : "-"}
                  vsExtra={homeDataPhotoShoot.countDupeKankiko !== undefined
                    ? `(重複数: ${selectedSeason.length > 1 ? "n/a" : homeDataPhotoShoot.countDupeKankiko})`
                    : null}
                  tip="ユニークの換気口基数"
                  valueExtra={`達成率: ${
                    homeDataPhotoShootBySeason.totalRatio !== undefined
                    ? `${homeDataPhotoShootBySeason.totalRatio.kankiko}%`
                    : "--"}`
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3}>
                <StatBoxSlim
                  title="地上設備基数"
                  value={homeDataPhotoShootBySeason.totalCounts !== undefined ? homeDataPhotoShootBySeason.totalCounts.ground_equipment : "-"}
                  // prevValue={homeDataPhotoShoot.countGroundEquipPrev !== undefined ? homeDataPhotoShoot.countGroundEquipPrev  : "-"}
                  vsExtra={homeDataPhotoShoot.countDupeGroundEquip !== undefined
                    ? `(重複数: ${selectedSeason.length > 1 ? "n/a" : homeDataPhotoShoot.countDupeGroundEquip})`
                    : null}
                  valueExtra={`達成率: ${
                    homeDataPhotoShootBySeason.totalRatio !== undefined
                    ? `${homeDataPhotoShootBySeason.totalRatio.ground_equipment}%`
                    : "--"}`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatBoxSlim
                  title="MH基数"
                  value={homeDataPhotoShootBySeason.totalCounts !== undefined ? homeDataPhotoShootBySeason.totalCounts.mh  : "-"}
                  // prevValue={homeDataPhotoShoot.countMhPrev !== undefined ? homeDataPhotoShoot.countMhPrev  : "-"}
                  vsExtra={homeDataPhotoShoot.countDupeMh !== undefined
                    ? `(重複数: ${selectedSeason.length > 1 ? "n/a" : homeDataPhotoShoot.countDupeMh})`
                    : null}
                  valueExtra={`達成率: ${
                    homeDataPhotoShootBySeason.totalRatio !== undefined
                    ? `${homeDataPhotoShootBySeason.totalRatio.mh}%`
                    : "--"}`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatBoxSlim
                  title="HH基数"
                  value={homeDataPhotoShootBySeason.totalCounts !== undefined ? homeDataPhotoShootBySeason.totalCounts.hh  : "-"}
                  // prevValue={homeDataPhotoShoot.countHhPrev !== undefined ? homeDataPhotoShoot.countHhPrev  : "-"}
                  vsExtra={homeDataPhotoShoot.countDupeHh !== undefined
                    ? `(重複数: ${selectedSeason.length > 1 ? "n/a" : homeDataPhotoShoot.countDupeHh})`
                    : null}
                  valueExtra={`達成率: ${
                    homeDataPhotoShootBySeason.totalRatio !== undefined
                    ?  `${homeDataPhotoShootBySeason.totalRatio.hh}%`
                    : "--"}`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatBoxSlim
                  title="撮影者数"
                  value={homeDataPhotoShoot.countUniqueUser !== undefined ? homeDataPhotoShoot.countUniqueUser  : "-"}
                  // prevValue={homeDataPhotoShoot.countUniqueUserPrev !== undefined ? homeDataPhotoShoot.countUniqueUserPrev  : "-"}
                  lastItem={true}
                  tip="ユニークの撮影者数"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item m={12} md={4} lg={2}>
        {homeDataPhotoShootBySeason.totalAssets !== undefined &&
         homeDataPhotoShootBySeason.totalAssets > 0 &&
        <>
        <Typography variant="body2">
          {homeDataPhotoShootBySeason.seasonData.length < 2
          ? `シーズン ${homeDataPhotoShootBySeason.seasonData[0]["seasonName"]}:`
          : `シーズン ${homeDataPhotoShootBySeason.seasonData[0]["seasonName"]} +${homeDataPhotoShootBySeason.seasonData.length - 1}シーズン:`
          }
        </Typography>
        <Typography variant="body2">{`
        ${dateRange[0]} ~ ${dateRange[1]} 時点のアセット撮影達成率`}</Typography>
        <div className={classes.lblPercent}>
          <Box width="100%" position="relative">
            <Box className={classes.tooltip}>
              {homeDataPhotoShootBySeason.totalSum !== undefined &&
              `${Math.round(homeDataPhotoShootBySeason.totalSum)}
              (${((homeDataPhotoShootBySeason.totalSum / homeDataPhotoShootBySeason.totalAssets) * 100).toFixed(2)}%)`
              }
            </Box>
            <Box>
              <LinearProgress variant="determinate" value={
                homeDataPhotoShootBySeason.totalSum !== undefined
                ? (homeDataPhotoShootBySeason.totalSum / homeDataPhotoShootBySeason.totalAssets) * 100
                : 0
              }
              />
            </Box>
            <Box display="flex" justifyContent="space-between" mt={1}>
              <Typography variant="body2" color="textSecondary">0</Typography>
              <Typography variant="body2" color="textSecondary">
                {homeDataPhotoShootBySeason.totalAssets !== undefined ? homeDataPhotoShootBySeason.totalAssets : "--"}
              </Typography>
            </Box>
          </Box>
        </div>
        </>
        }
      </Grid>
    </Grid>
  );
};

PhotoShoot.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  dateRange: state.page.dateRangePicTree,
  homeDataPhotoShoot: state.pictree.homeDataPhotoShoot,
  selectedSeason: state.pictree.selectedSeason,
  seasonData: state.pictree.seasonData,
  homeDataPhotoShootBySeason: state.pictree.homeDataPhotoShootBySeason,
});

const mapDispatchToProps = (dispatch) => ({
  //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(PhotoShoot));
