import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
} from "@material-ui/core";
import ShowChartIcon from '@material-ui/icons/ShowChart';
import MapIcon from '@material-ui/icons/Map';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import {
  setCurrentPage,
  getHomePictreePhotoShoot,
  setPictreeView,
  getPictreeDataPhotoShootTrend,
  getPhotoShootDetailsData,
  setPictreeChartSize,
} from "actions";
import * as C from "components";
import * as V from "styles/variables";
import PhotoShoot from "components/PicTree/Home/PhotoShoot";
import ChartConfig from "utils/ChartConfig";
import LineChartPictreePhotoShoot from "components/Charts/LineChartPictreePhotoShoot";
import PicTreeTablePhotoShoot from "components/Table/PicTreeTablePhotoShoot";
import PhotoShootMap from "components/PicTree/Game/Map/PhotoShootMap";
import SingleSelect from "components/Select/SingleSelect";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const PicTreeReviewSummary = ({
  setCurrentPage,
  pageTopLoader,
  dateRangePicTree,
  getHomePictreePhotoShoot,
  setPictreeView,
  pictreeView,
  photoShootTrend,
  dataPhotoShootDetails,
  getPictreeDataPhotoShootTrend,
  getPhotoShootDetailsData,
  setPictreeChartSize,
}) => {
  const classes = useStyles();
  const [chartState, setChartState] = useState(true);
  const [chartSize, setChartSize] = useState({ name: "日別", value: "day" });
  const [initialDataCall, setInitialDataCall] = useState(true);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    setPictreeView("photo/chart");
  }, []);

  useEffect(() => {
    if (dateRangePicTree[0] !== null && dateRangePicTree[1]!== null
      && initialDataCall) {
      getHomePictreePhotoShoot();
      getPictreeDataPhotoShootTrend("day");
      setInitialDataCall(false);
    }
  }, [dateRangePicTree]);

  useEffect(() => {
    if (pictreeView === "photo/map") {
      getPhotoShootDetailsData();
    }
  }, [pictreeView]);

  const handleView = (view) => {
    setPictreeView(view);
  };

  const handleChange = (event) => {
    setChartSize(event.target.value);
    setPictreeChartSize(event.target.value)
    getPictreeDataPhotoShootTrend(event.target.value);
  };

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>PicTrée</Typography>
              <Typography variant="body1">撮影</Typography>
              <Typography variant="body1">通常モード</Typography>
              <Typography variant="body1">概要</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <C.CalendarRangePicker mDate={"2024/04/13"} page="pictree" pictree />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{ marginTop: 0 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <div className={classes.cardContentSection}>
                  <PhotoShoot />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: 16, marginRight: 16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item style={{ marginTop: 0, marginLeft: 48, }}>
          {pictreeView === "photo/chart" &&
            <SingleSelect
              selected={chartSize}
              options={[
                { name: "時間別", value: "hour" },
                { name: "日別", value: "day" },
              ]}
              handleChange={handleChange}
            />
          }
          </Grid>
          {/* <Grid item>
            <Grid
              container
              spacing={0}
              direction="row"
              style={{ marginTop: 0, marginRight: 16, marginBottom: 8 }}
              justifyContent="flex-end"
              >
              <Grid item></Grid>
              <Grid item className={classes.fillRemailSpace}></Grid>
              <Grid item>
                <Chip avatar={<ShowChartIcon />} label="チャート" onClick={() => handleView("photo/chart")}
                  variant={pictreeView === "photo/chart" ? "default" : "outlined"}
                />
              </Grid>
              <Grid item>
                <Chip avatar={<MapIcon />} label="マップ" onClick={() => handleView("photo/map")}
                  variant={pictreeView === "photo/map" ? "default" : "outlined"}
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>

        {pictreeView === "photo/chart" &&
          <>
          <Grid container spacing={1} className={classes.gridSection}>
            <Grid item xs={12} md={12} lg={12}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : chartState ? (
                photoShootTrend.length > 0 ? (
                  <LineChartPictreePhotoShoot
                    legend={ChartConfig.legendPicTreePhotoShootTrend}
                    lblSaveImage={""}
                    xAisData={photoShootTrend[0]["date"]}
                    seriesData={photoShootTrend}
                    chartType={"line"}
                  />
                ) : (
                  <div className={classes.selectGameTxt}>
                    <Chip
                      label="No Data"
                      style={{
                        color: V.textColor,
                        backgroundColor: V.placeholderColor,
                      }}
                    />
                  </div>
                )
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.gridSection}>
            <Grid item xs={12} md={12} lg={12}>
              <PicTreeTablePhotoShoot game="PicTree" />
            </Grid>
          </Grid>
          </>
        }
        {pictreeView === "photo/map" &&
         dataPhotoShootDetails.length > 0 &&
          <PhotoShootMap records={dataPhotoShootDetails} type="photoshoot" width="100%" height="70vh" />
        }
      </C.ContentWrapper>
    </Fragment>
  );
};

PicTreeReviewSummary.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  dateRangePicTree: state.page.dateRangePicTree,
  pictreeView: state.pictree.pictreeView,
  photoShootTrend: state.pictree.photoShootTrend,
  dataPhotoShootDetails: state.pictree.dataPhotoShootDetails,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getHomePictreePhotoShoot: () => dispatch(getHomePictreePhotoShoot()),
  setPictreeView: (param) => dispatch(setPictreeView(param)),
  getPictreeDataPhotoShootTrend: (param) => dispatch(getPictreeDataPhotoShootTrend(param)),
  getPhotoShootDetailsData: () => dispatch(getPhotoShootDetailsData()),
  setPictreeChartSize: (param) => dispatch(setPictreeChartSize(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(PicTreeReviewSummary));
