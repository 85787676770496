const AFFILIATE = {
    SET_CREATE_STATUS: 'SET_CREATE_STATUS',
    SET_AFFILIATOR_NAME: 'SET_AFFILIATOR_NAME',
    SET_AFFILIATOR_EMAIL: 'SET_AFFILIATOR_EMAIL',
    SET_AFFILIATOR_TYPE: 'SET_AFFILIATOR_TYPE',
    SET_AFFILIATOR_GAME: 'SET_AFFILIATOR_GAME',
    GET_AFFILIATOR_GROUP: 'GET_AFFILIATOR_GROUP',
    SET_AFFILIATOR_GROUP: 'SET_AFFILIATOR_GROUP',
    GET_AFFILIATOR_GROUP_SELECTED: 'GET_AFFILIATOR_GROUP_SELECTED',
    SET_AFFILIATOR_GROUP_SELECTED: 'SET_AFFILIATOR_GROUP_SELECTED',
    SET_AFFILIATOR_MEDIA: 'SET_AFFILIATOR_MEDIA',
    SET_AFFILIATOR_FT: 'SET_AFFILIATOR_FT',
    SET_AFFILIATOR_REG: 'SET_AFFILIATOR_REG',
    SET_AFFILIATOR_CONV: 'SET_AFFILIATOR_CONV',
    SET_AFFILIATOR_CODE: 'SET_AFFILIATOR_CODE',
    SET_CREATE_BTN_STATE: 'SET_CREATE_BTN_STATE',
    SET_AFFILIATOR: 'SET_AFFILIATOR',
    UPDATE_AFFILIATOR: 'UPDATE_AFFILIATOR',
    DELETE_AFFILIATE: 'DELETE_AFFILIATE',
    SET_ACTIVE_AFFILIATE_NUMBER: 'SET_ACTIVE_AFFILIATE_NUMBER',
    SET_UPDATE_AFFILIATE_STATE: 'SET_UPDATE_AFFILIATE_STATE',
    SET_AFFILIATE_LIST_DATA: 'SET_AFFILIATE_LIST_DATA',
    GET_AFFILIATE_LIST_DATA_BY_KEY: 'GET_AFFILIATE_LIST_DATA_BY_KEY',
    UPDATE_AFFILIATE_LIST_DATA: 'UPDATE_AFFILIATE_LIST_DATA',
    SET_AFFILIATE_ID_TO_UPDATE: 'SET_AFFILIATE_ID_TO_UPDATE',
    CLEAR_AFFILIATE_FIELDS: 'CLEAR_AFFILIATE_FIELDS',
    SET_SUBMIT_AFFILIATE: 'SET_SUBMIT_AFFILIATE',
    SET_SUMMARY_DATA_FETCH_STATE: 'SET_SUMMARY_DATA_FETCH_STATE',
    STOP_WATCHER_TASK: 'STOP_WATCHER_TASK',
    SET_LAST_UPDATE_DATE_TIME: 'SET_LAST_UPDATE_DATE_TIME',
    GET_TREND_DATA_BY_AID: 'GET_TREND_DATA_BY_AID',
    SET_TREND_CHART_DATA: 'SET_TREND_CHART_DATA',
    SET_NO_TREND_DATA: 'SET_NO_TREND_DATA',
    SET_LOADING_TREND_DATA: 'SET_LOADING_TREND_DATA',
    SET_ALL_BI_USERS_LIST: 'SET_ALL_BI_USERS_LIST',
}

const CAMPAIGN = {
    GET_CAMPAIGN_LIST_DATA_BY_KEY: 'GET_CAMPAIGN_LIST_DATA_BY_KEY',
    SET_CAMPAIGN_LIST_DATA: 'SET_CAMPAIGN_LIST_DATA',
    SET_CAMPAIGN_DATA_LOAD_BY_ID: 'SET_CAMPAIGN_DATA_LOAD_BY_ID',
    SET_CAMPAIGN_ID_TO_FETCH: 'SET_CAMPAIGN_ID_TO_FETCH',
    GET_CAMPAIGN_STAT_DATA_BY_ID: 'GET_CAMPAIGN_STAT_DATA_BY_ID',
    SET_CAMPAIGN_STAT_BY_ID: 'SET_CAMPAIGN_STAT_BY_ID',
    SET_CAMPAIGN_TREND_FETCH_RID: 'SET_CAMPAIGN_TREND_FETCH_RID',
    SET_CAMPAIGN_TREND_DATA_LOADING_BY_RID: 'SET_CAMPAIGN_TREND_DATA_LOADING_BY_RID',
    GET_TREND_FIRST_TIME_BY_RID: 'GET_TREND_FIRST_TIME_BY_RID',
    SET_TREND_FIRST_TIME_BY_RID: 'SET_TREND_FIRST_TIME_BY_RID',
    SET_TREND_GAME_VISIT_BY_RID: 'SET_TREND_GAME_VISIT_BY_RID',
    SET_TREND_REGIST_BY_RID: 'SET_TREND_REGIST_BY_RID',
    SET_CAMPAIGN_TREND_CHART_DATA: 'SET_CAMPAIGN_TREND_CHART_DATA',
    GET_CAMPAIGN_SUMMARY_DATA_BY_DATES: 'GET_CAMPAIGN_SUMMARY_DATA_BY_DATES',
    SET_CAMPAIGN_DATE_RANGE: 'SET_CAMPAIGN_DATE_RANGE',
    SET_PREDEFINED_CAMPAIGN_DATE_RANGE: 'SET_PREDEFINED_CAMPAIGN_DATE_RANGE',
    SET_CAMPAIGN_VIEW_STATS: 'SET_CAMPAIGN_VIEW_STATS',
    SET_CAMPAIGN_SELECTED_STATS: 'SET_CAMPAIGN_SELECTED_STATS',
}

const USERS = {
    GET_USER_PAGE_LIST_TOTAL_NUM: 'GET_USER_PAGE_LIST_TOTAL_NUM',
    SET_USER_PAGE_LIST_TOTAL_NUM: 'SET_USER_PAGE_LIST_TOTAL_NUM',
    GET_USER_PAGE_LIST_DATA: 'GET_USER_PAGE_LIST_DATA',
    SET_USER_PAGE_LIST_DATA: 'SET_USER_PAGE_LIST_DATA',
    SET_USER_PAGE_LIST_PAGINATION: 'SET_USER_PAGE_LIST_PAGINATION',
    SET_USER_PAGE_TABLE_LOADING: 'SET_USER_PAGE_TABLE_LOADING',
    SET_USER_PAGE_TABLE_TOTAL: 'SET_USER_PAGE_TABLE_TOTAL',
    GET_USERS_DATA_BY_AID: 'GET_USERS_DATA_BY_AID',
    SET_USERS_LIST_DATA: 'SET_USERS_LIST_DATA',
    SET_USERS_REG_LIST_DATA: 'SET_USERS_REG_LIST_DATA',
    SET_USERS_GAME_DIRECT_LIST_DATA: 'SET_USERS_GAME_DIRECT_LIST_DATA',
    CLEAR_USERS_DATA_STORED: 'CLEAR_USERS_DATA_STORED',
    SET_NO_USER_DATA: 'SET_NO_USER_DATA',
    GET_REG_DATA_BY_AID: 'GET_REG_DATA_BY_AID',
    CLEAR_USERS_REG_LIST_DATA: 'CLEAR_USERS_REG_LIST_DATA',
    CLEAR_USERS_GAME_DIRECT_LIST_DATA: 'CLEAR_USERS_GAME_DIRECT_LIST_DATA',
    HANDLE_USERS_FILTER: 'HANDLE_USERS_FILTER',
    SET_USERS_FILTER: 'SET_USERS_FILTER',
    FETCH_USERS_BY_FILTER: 'FETCH_USERS_BY_FILTER',
    SET_USER_COUNT_FILTERED: 'SET_USER_COUNT_FILTERED',
    SET_USER_FILTER_QUERY: 'SET_USER_FILTER_QUERY',
    SET_USER_FILTER_CAMPAIGNS: 'SET_USER_FILTER_CAMPAIGNS',
    SET_USER_PAGE_NUMBER: 'SET_USER_PAGE_NUMBER',
    GET_USER_LIST_DATA: 'GET_USER_LIST_DATA',
    GET_USER_LIST_DATA_BY_TERM: 'GET_USER_LIST_DATA_BY_TERM',
    SET_USERS_STAT_DATA: 'SET_USERS_STAT_DATA',
    SET_USER_PAGE_LIST_DATA_FILTER: 'SET_USER_PAGE_LIST_DATA_FILTER',
    SET_USER_CSV_DATA: 'SET_USER_CSV_DATA',
    SET_USER_CSV_DOWNLOADER: 'SET_USER_CSV_DOWNLOADER',
    GET_USER_CSV_DATA: 'GET_USER_CSV_DATA',
}

const MAP = {
    CHANGE_THEME: 'CHANGE_THEME',
    SET_FILTER_STATUS: 'SET_FILTER_STATUS',
    SHOW_FILTER_ON_MAP: 'SHOW_FILTER_ON_MAP',
    SET_POI_SEARCH_DATA: 'SET_POI_SEARCH_DATA',
    SET_ZOOM_LEVEL: 'SET_ZOOM_LEVEL',
    SET_POI_COORD: 'SET_POI_COORD',
    SET_SHOW_POI_DATA: 'SET_SHOW_POI_DATA',
    SHOW_POI_DATA_ON_MAP: 'SHOW_POI_DATA_ON_MAP',
    RESET_POI_SEARCH_DATA: 'RESET_POI_SEARCH_DATA',
    SET_POLYGON: 'SET_POLYGON',
    CLEAR_POI_SEARCH_DATA: 'CLEAR_POI_SEARCH_DATA',
    SET_MAP_FITBOUNDS: 'SET_MAP_FITBOUNDS',
    UPDATE_SELECTED_POI: 'UPDATE_SELECTED_POI',
}

const PAGE = {
    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
    SET_PAGE_TOP_LOADER: 'SET_PAGE_TOP_LOADER',
    SET_DATE_RANGE: 'SET_DATE_RANGE',
    GET_DATE_RANGE: 'GET_DATE_RANGE',
    SET_DATE_RANGE_PICTREE: 'SET_DATE_RANGE_PICTREE',
    GET_DATE_RANGE_PICTREE: 'GET_DATE_RANGE_PICTREE',
    SET_DATE_RANGE_PICTREE_MIN_MAX: 'SET_DATE_RANGE_PICTREE_MIN_MAX',
    SET_DATE_RANGE_PICTREE_NTT: 'SET_DATE_RANGE_PICTREE_NTT',
    GET_DATE_RANGE_PICTREE_NTT: 'GET_DATE_RANGE_PICTREE_NTT',
    SET_DATE_RANGE_PICTREE_MISSION: 'SET_DATE_RANGE_PICTREE_MISSION',
    GET_DATE_RANGE_PICTREE_MISSION: 'GET_DATE_RANGE_PICTREE_MISSION',
    SET_MAU_DATE_RANGE: 'SET_MAU_DATE_RANGE',
    SET_MAU_PREV_DATE_RANGE: 'SET_MAU_PREV_DATE_RANGE',
    SET_WAU_DATE_RANGE: 'SET_WAU_DATE_RANGE',
    SET_WAU_PREV_DATE_RANGE: 'SET_WAU_PREV_DATE_RANGE',
    SET_COMPARISON_DATE_DIFF: 'SET_COMPARISON_DATE_DIFF',
    SET_COMPARISON_DATE_DIFF_DISPLAY: 'SET_COMPARISON_DATE_DIFF_DISPLAY',
    GET_COMPARISON_DATE_DIFF: 'GET_COMPARISON_DATE_DIFF',
    SET_PREV_DATE_RANGE: 'SET_PREV_DATE_RANGE',
    GET_PREV_DATE_RANGE: 'GET_PREV_DATE_RANGE',
    SET_PREV_DATE_RANGE_PICTREE: 'SET_PREV_DATE_RANGE_PICTREE',
    GET_PREV_DATE_RANGE_PICTREE: 'GET_PREV_DATE_RANGE_PICTREE',
    SET_TABLE_ROW_SELECTED: 'SET_TABLE_ROW_SELECTED',
    SET_CREATE_STATUS: 'SET_CREATE_STATUS',
    SET_ID_TOKEN: 'SET_ID_TOKEN',
    SET_ACCESS_KEY: 'SET_ACCESS_KEY',
    GET_HOME_PAGE_DATA: 'GET_HOME_PAGE_DATA',
    GET_PUZZLE_PAGE_DATA: 'GET_PUZZLE_PAGE_DATA',
    SET_BTN_STATE_DATA_FETCH: 'SET_BTN_STATE_DATA_FETCH',
    SET_DEP_PRICE: 'SET_DEP_PRICE',
    GET_DEP_PRICE: 'GET_DEP_PRICE',
    SET_CURRENCY: 'SET_CURRENCY',
    SET_FILTER_DRAWER_STATE: 'SET_FILTER_DRAWER_STATE',
}

const GA = {
    GET_ALL_EVENT_DATA: 'GET_ALL_EVENT_DATA',
}

const HOME = {
    GET_HOME_TOTAL_STATS_DATA: 'GET_HOME_TOTAL_STATS_DATA',
    SET_HOME_TOTAL_STATS_DATA: 'SET_HOME_TOTAL_STATS_DATA',
    SET_HOME_TOTAL_STATS_BY_X_DATE: 'SET_HOME_TOTAL_STATS_BY_X_DATE',
    GET_HOME_ACQUISITION_DATA: 'GET_HOME_ACQUISITION_DATA',
    SET_HOME_ACQUISITION_DATA: 'SET_HOME_ACQUISITION_DATA',
    GET_HOME_ACQUISITION_PREV_DATA: 'GET_HOME_ACQUISITION_PREV_DATA',
    SET_HOME_ACQUISITION_PREV_DATA: 'SET_HOME_ACQUISITION_PREV_DATA',
    SET_HOME_ACQUISITION_LAST_TREND_DATA: 'SET_HOME_ACQUISITION_LAST_TREND_DATA',
    GET_HOME_ACTIVE_USERS_DATA: 'GET_HOME_ACTIVE_USERS_DATA',
    SET_HOME_ACTIVE_USERS_DATA: 'SET_HOME_ACTIVE_USERS_DATA',
    SET_HOME_ACTIVE_USERS_GEO_DATA: 'SET_HOME_ACTIVE_USERS_GEO_DATA',
    SET_HOME_ACTIVE_USERS_GEO_CHART_DATA: 'SET_HOME_ACTIVE_USERS_GEO_CHART_DATA',
    GET_HOME_TIER_DATA: 'GET_HOME_TIER_DATA',
    SET_HOME_TIER_DATA: 'SET_HOME_TIER_DATA',
    SET_HOME_TIER_PREV_DATA: 'SET_HOME_TIER_PREV_DATA',
    SET_HOME_TIER_TREND_DATA: 'SET_HOME_TIER_TREND_DATA',
    GET_HOME_NFT_HOLDER_DATA: 'GET_HOME_NFT_HOLDER_DATA',
    SET_HOME_NFT_HOLDER_DATA: 'SET_HOME_NFT_HOLDER_DATA',
}

const PUZZLE = {
    GET_ACQUISITION_DATA: 'GET_ACQUISITION_DATA',
    SET_ACQUISITION_DATA_LOADING: 'SET_ACQUISITION_DATA_LOADING',
    SET_ACQUISITION_DATA_REG: 'SET_ACQUISITION_DATA_REG',
    SET_ACQUISITION_DATA_REG_GENDER: 'SET_ACQUISITION_DATA_REG_GENDER',
    SET_ACQUISITION_DATA_REG_AGE_GROUP: 'SET_ACQUISITION_DATA_REG_AGE_GROUP',
    SET_ACQUISITION_DATA_REG_AD_VS_ORG: 'SET_ACQUISITION_DATA_REG_AD_VS_ORG',
    SET_ACQUISITION_DATA_REG_NEW_VS_EXIST: 'SET_ACQUISITION_DATA_REG_NEW_VS_EXIST',
    SET_ACQUISITION_DATA_REG_TREND: 'SET_ACQUISITION_DATA_REG_TREND',
    GET_ACTIVE_USERS_DATA: 'GET_ACTIVE_USERS_DATA',
    SET_ACTIVE_USERS_DATA: 'SET_ACTIVE_USERS_DATA',
    SET_ACTIVE_USERS_TREND_DATA: 'SET_ACTIVE_USERS_TREND_DATA',
    GET_USER_EVENT_DATA: 'GET_USER_EVENT_DATA',
    SET_USER_EVENT_DATA: 'SET_USER_EVENT_DATA',
    SET_USER_EVENT_TREND_DATA: 'SET_USER_EVENT_TREND_DATA',
    GET_USER_STAGE_CLEAR_DATA: 'GET_USER_STAGE_CLEAR_DATA',
    SET_USER_STAGE_CLEAR_DATA: 'SET_USER_STAGE_CLEAR_DATA',
    GET_USER_STAGE_FAILED_DATA: 'GET_USER_STAGE_FAILED_DATA',
    SET_USER_STAGE_FAILED_DATA: 'SET_USER_STAGE_FAILED_DATA',
}

const PUZZLE_GEO = {
    GET_ACQUISITION_GEO_DATA_REG: 'GET_ACQUISITION_GEO_DATA_REG',
    SET_ACQUISITION_GEO_DATA_REG: 'SET_ACQUISITION_GEO_DATA_REG',
    SET_ACQUISITION_COUNTRY_BY_AD: 'SET_ACQUISITION_COUNTRY_BY_AD',
    SET_ACQUISITION_COUNTRY_BY_ORGANIC: 'SET_ACQUISITION_COUNTRY_BY_ORGANIC',
}

const DATA = {
    SET_COHORT_FOCUSED: 'SET_COHORT_FOCUSED',
    SET_COHORT_SIZE: 'SET_COHORT_SIZE',
    SET_COHORT_SIZE_SELECTED: 'SET_COHORT_SIZE_SELECTED',
    SET_COHORT_COUNTRY_OPTIONS: 'SET_COHORT_COUNTRY_OPTIONS',
    SET_COHORT_COUNTRY_SELECTED: 'SET_COHORT_COUNTRY_SELECTED',
    SET_COHORT_RID_OPTIONS: 'SET_COHORT_RID_OPTIONS',
    SET_COHORT_RID_SELECTED: 'SET_COHORT_RID_SELECTED',
    SET_COHORT_RANGE: 'SET_COHORT_RANGE',
    SET_COHORT_RANGE_SELECTED: 'SET_COHORT_RANGE_SELECTED',
    GET_COHORT_DATA: 'GET_COHORT_DATA',
    SET_COHORT_DATA: 'SET_COHORT_DATA',
    SET_COHORT_NFT_DATA: 'SET_COHORT_NFT_DATA',
    SET_COHORT_DATE_RANGE: 'SET_COHORT_DATE_RANGE',
    SET_ACTIVE_USERS_UNIQUE_TOTAL_DATA: 'SET_ACTIVE_USERS_UNIQUE_TOTAL_DATA',
    SET_ACTIVE_USERS_UNIQUE_PAID_DATA: 'SET_ACTIVE_USERS_UNIQUE_PAID_DATA',
    GET_ACTIVE_USERS_PAGE_DATA: 'GET_ACTIVE_USERS_PAGE_DATA',
    SET_ACTIVE_USERS_PAGE_DATA: 'SET_ACTIVE_USERS_PAGE_DATA',
    GET_ACTIVE_USERS_PAGE_DATA_BY_GAME: 'GET_ACTIVE_USERS_PAGE_DATA_BY_GAME',
    SET_ACTIVE_USERS_PAGE_DATA_BY_GAME: 'SET_ACTIVE_USERS_PAGE_DATA_BY_GAME',
    GET_ACTIVE_USERS_COUNTRIES_BY_GAME: 'GET_ACTIVE_USERS_COUNTRIES_BY_GAME',
    GET_ACTIVE_USERS_RID_BY_GAME: 'GET_ACTIVE_USERS_RID_BY_GAME',
    SET_ACTIVE_USERS_GAME_OPTION: 'SET_ACTIVE_USERS_GAME_OPTION',
    SET_ACTIVE_USERS_GAME_SELECTED: 'SET_ACTIVE_USERS_GAME_SELECTED',
    SET_ACTIVE_USERS_SIZE_OPTION: 'SET_ACTIVE_USERS_SIZE_OPTION',
    SET_ACTIVE_USERS_SIZE_SELECTED: 'SET_ACTIVE_USERS_SIZE_SELECTED',
    SET_ACTIVE_USERS_COUNTRY_OPTION: 'SET_ACTIVE_USERS_COUNTRY_OPTION',
    SET_ACTIVE_USERS_COUNTRY_SELECTED: 'SET_ACTIVE_USERS_COUNTRY_SELECTED',
    SET_ACTIVE_USERS_RID_OPTION: 'SET_ACTIVE_USERS_RID_OPTION',
    SET_ACTIVE_USERS_RID_SELECTED: 'SET_ACTIVE_USERS_RID_SELECTED',
    SET_ACTIVE_USERS_DATE_RANGE: 'SET_ACTIVE_USERS_DATE_RANGE',
    SET_ACTIVE_USERS_DATE_DIFF: 'SET_ACTIVE_USERS_DATE_DIFF',
    RESET_ACTIVE_USERS_GAME_SELECTED: 'RESET_ACTIVE_USERS_GAME_SELECTED',
    GET_ACTIVE_USERS_COUNTRIES: 'GET_ACTIVE_USERS_COUNTRIES',
    SET_ACTIVE_USERS_COUNTRY_LOADING: 'SET_ACTIVE_USERS_COUNTRY_LOADING',
    SET_ACTIVE_USERS_RID_LOADING: 'SET_ACTIVE_USERS_RID_LOADING',
    SET_ALL_PM_MENU_SELECT: 'SET_ALL_PM_MENU_SELECT',
    GET_CAMPAIGN_BY_GAME: 'GET_CAMPAIGN_BY_GAME',
    SET_GAME_OPTION_LOADER: 'SET_GAME_OPTION_LOADER',
    SET_CAMPAIGN_OPTION: 'SET_CAMPAIGN_OPTION',
    SET_GAME_SELECTED: 'SET_GAME_SELECTED',
    SET_CAMPAIGN_SELECTED: 'SET_CAMPAIGN_SELECTED',
    GET_GAME_NFT_TREND_PAGE_DATA: 'GET_GAME_NFT_TREND_PAGE_DATA',
    SET_GAME_NFT_TREND_PAGE_DATA: 'SET_GAME_NFT_TREND_PAGE_DATA',
    SET_GAME_NFT_TREND_DATA_LOADING: 'SET_GAME_NFT_TREND_DATA_LOADING',
    SET_GAME_NFT_TREND_DATE_RANGE: 'SET_GAME_NFT_TREND_DATE_RANGE',
    SET_GAME_NFT_TREND_STORE_ALL: 'SET_GAME_NFT_TREND_STORE_ALL',
    SET_GAME_NFT_TREND_STORE_ALL_FIAT: 'SET_GAME_NFT_TREND_STORE_ALL_FIAT',
    SET_GAME_NFT_TREND_COUNT_AUCTION: 'SET_GAME_NFT_TREND_COUNT_AUCTION',
    SET_GAME_NFT_TREND_AMOUNT_AUCTION: 'SET_GAME_NFT_TREND_AMOUNT_AUCTION',
    SET_GAME_NFT_TREND_COUNT_STORE: 'SET_GAME_NFT_TREND_COUNT_STORE',
    SET_GAME_NFT_TREND_AMOUNT_STORE: 'SET_GAME_NFT_TREND_AMOUNT_STORE',
    SET_GAME_NFT_TREND_COUNT_STORE_FIAT: 'SET_GAME_NFT_TREND_COUNT_STORE_FIAT',
    SET_GAME_NFT_TREND_AUCTION_ALL: 'SET_GAME_NFT_TREND_AUCTION_ALL',
    SET_GAME_NFT_TREND_DATA_AMOUNT_STORE_FIAT: 'SET_GAME_NFT_TREND_DATA_AMOUNT_STORE_FIAT',
    GET_IN_GAME_SALES_DATA_OVERVIEW: 'GET_IN_GAME_SALES_DATA_OVERVIEW',
    SET_IN_GAME_SALES_DATA_OVERVIEW: 'SET_IN_GAME_SALES_DATA_OVERVIEW',
    SET_IN_GAME_SALES_LOADING: 'SET_IN_GAME_SALES_LOADING',
    SET_INGAME_SALES_OVERVIEW_TAB: 'SET_INGAME_SALES_OVERVIEW_TAB',
    SET_INGAME_SALES_FOCUS_TAB_INDEX: 'SET_INGAME_SALES_FOCUS_TAB_INDEX',
    GET_INGAME_SALES_DATA_BY_COUNTRY: 'GET_IN_GAME_SALES_DATA_BY_COUNTRY',
    SET_INGAME_SALES_DATA_BY_COUNTRY: 'SET_INGAME_SALES_DATA_BY_COUNTRY',
    GET_INGAME_SALES_DATA_BY_ITEM: 'GET_INGAME_SALES_DATA_BY_ITEM',
    SET_INGAME_SALES_DATA_BY_ITEM: 'SET_INGAME_SALES_DATA_BY_ITEM',
    GET_INGAME_SALES_DATA_BY_PMID: 'GET_INGAME_SALES_DATA_BY_PMID',
    SET_INGAME_SALES_DATA_BY_PMID: 'SET_INGAME_SALES_DATA_BY_PMID',
    GET_INGAME_SALES_DATA_BY_RID: 'GET_INGAME_SALES_DATA_BY_RID',
    SET_INGAME_SALES_DATA_BY_RID: 'SET_INGAME_SALES_DATA_BY_RID',
    GET_INGAME_SALES_DATA_BY_OCCURRENCE: 'GET_INGAME_SALES_DATA_BY_OCCURRENCE',
    SET_INGAME_SALES_DATA_BY_OCCURRENCE: 'SET_INGAME_SALES_DATA_BY_OCCURRENCE',
    GET_NFT_SALES_DATA_BY_OCCURRENCE: 'GET_NFT_SALES_DATA_BY_OCCURRENCE',
    SET_NFT_SALES_DATA_BY_OCCURRENCE: 'SET_NFT_SALES_DATA_BY_OCCURRENCE',
    SET_NFT_SALES_DATA_BY_OCCURRENCE_TYPE: 'SET_NFT_SALES_DATA_BY_OCCURRENCE_TYPE',
    SET_NFT_SALES_DATA_BY_OCCURRENCE_GAME: 'SET_NFT_SALES_DATA_BY_OCCURRENCE_GAME',
    GET_INGAME_SALES_DATA_BY_GAME: 'GET_INGAME_SALES_DATA_BY_GAME',
    SET_INGAME_SALES_DATA_BY_GAME: 'SET_INGAME_SALES_DATA_BY_GAME',
    GET_INGAME_ITEM_TX_HISTORY_DATA: 'GET_INGAME_ITEM_TX_HISTORY_DATA',
    GET_INGAME_ITEM_TX_HISTORY_DATA_BY_TERM: 'GET_INGAME_ITEM_TX_HISTORY_DATA_BY_TERM',
    SET_INGAME_ITEM_TX_HISTORY_DATA_LOADING: 'SET_INGAME_ITEM_TX_HISTORY_DATA_LOADING',
    SET_INGAME_ITEM_TX_HISTORY_DATA: 'SET_INGAME_ITEM_TX_HISTORY_DATA',
    SET_INGAME_ITEM_TX_HISTORY_STAT_DATA: 'SET_INGAME_ITEM_TX_HISTORY_STAT_DATA',
    SET_INGAME_PAGE_NUMBER: 'SET_INGAME_PAGE_NUMBER',
    SET_INGAME_ITEM_TX_HISTORY_SEARCH_TERM: 'SET_INGAME_ITEM_TX_HISTORY_SEARCH_TERM',
    GET_INGAME_SALES_DATA_CSV: 'GET_INGAME_SALES_DATA_CSV',
    SET_INGAME_SALES_DATA_CSV: 'SET_INGAME_SALES_DATA_CSV',
    GET_INGAME_ITEM_OPTION_DATA: 'GET_INGAME_ITEM_OPTION_DATA',
    SET_INGAME_ITEM_OPTION_DATA_LOADING: 'SET_INGAME_ITEM_OPTION_DATA_LOADING',
    SET_INGAME_ITEM_OPTION_DATA: 'SET_INGAME_ITEM_OPTION_DATA',
    SET_INGAME_ITEM_OPTION_SELECTED: 'SET_INGAME_ITEM_OPTION_SELECTED',
    SET_INGAME_PAYMENT_OPTION_SELECTED: 'SET_INGAME_PAYMENT_OPTION_SELECTED',
    GET_INGAME_PMID_OPTION_DATA: 'GET_INGAME_PMID_OPTION_DATA',
    SET_INGAME_PMID_OPTION_DATA_LOADING: 'SET_INGAME_PMID_OPTION_DATA_LOADING',
    SET_INGAME_PMID_OPTION_DATA: 'SET_INGAME_PMID_OPTION_DATA',
    SET_INGAME_PMID_OPTION_SELECTED: 'SET_INGAME_PMID_OPTION_SELECTED',

    // TREND
    SET_INGAME_SALES_DATA_TREND_LOADING: 'SET_INGAME_SALES_DATA_TREND_LOADING',
    SET_INGAME_SALES_TREND_LOADING: 'SET_INGAME_SALES_TREND_LOADING',
    GET_INGAME_SALES_DATA_TREND: 'GET_INGAME_SALES_DATA_TREND',
    SET_INGAME_SALES_DATA_TREND: 'SET_INGAME_SALES_DATA_TREND',
    SET_INGAME_SALES_TREND_TYPE: 'SET_INGAME_SALES_TREND_TYPE',
    SET_INGAME_SALES_TREND_DATA_CHART_COUNT: 'SET_INGAME_SALES_TREND_DATA_CHART_COUNT',
    SET_INGAME_SALES_TREND_DATA_CHART_AMOUNT: 'SET_INGAME_SALES_TREND_DATA_CHART_AMOUNT',
    SET_INGAME_SALES_TREND_DATA_CHART_COUNT_UNIQUE: 'SET_INGAME_SALES_TREND_DATA_CHART_COUNT_UNIQUE',
    SET_INGAME_DEP_SALES_TREND_DATA_CHART_COUNT: 'SET_INGAME_DEP_SALES_TREND_DATA_CHART_COUNT',
    SET_INGAME_DEP_SALES_TREND_DATA_CHART_AMOUNT: 'SET_INGAME_DEP_SALES_TREND_DATA_CHART_AMOUNT',
    SET_INGAME_DEP_SALES_TREND_DATA_CHART_COUNT_UNIQUE: 'SET_INGAME_DEP_SALES_TREND_DATA_CHART_COUNT_UNIQUE',
    SET_INGAME_SALES_TREND_DATA_CHART_COUNT_BY_ITEM: 'SET_INGAME_SALES_TREND_DATA_CHART_COUNT_BY_ITEM',
    SET_INGAME_SALES_TREND_DATA_CHART_AMOUNT_BY_ITEM: 'SET_INGAME_SALES_TREND_DATA_CHART_AMOUNT_BY_ITEM',
    SET_INGAME_SALES_TREND_DATA_TABLE: 'SET_INGAME_SALES_TREND_DATA_TABLE',
    GET_INGAME_SALES_TREND_DATA_ITEMS: 'GET_INGAME_SALES_TREND_DATA_ITEMS',
    SET_INGAME_SALES_TREND_DATA_ITEMS: 'SET_INGAME_SALES_TREND_DATA_ITEMS',
    SET_INGAME_SALES_TREND_ITEM_SELECTED: 'SET_INGAME_SALES_TREND_ITEM_SELECTED',
}

const KPI = {
    GET_AVG_REVENUE_PAGE_DATA: 'GET_AVG_REVENUE_PAGE_DATA',
    GET_AVG_REVENUE_FILTER_OPTION: 'GET_AVG_REVENUE_FILTER_OPTION',
    SET_AVG_REVENUE_GAME_OPTION: 'SET_AVG_REVENUE_GAME_OPTION',
    SET_AVG_REVENUE_COUNTRY_OPTION: 'SET_AVG_REVENUE_COUNTRY_OPTION',
    SET_AVG_REVENUE_GAME_SELECTED: 'SET_AVG_REVENUE_GAME_SELECTED',
    SET_AVG_REVENUE_COUNTRY_SELECTED: 'SET_AVG_REVENUE_COUNTRY_SELECTED',
    SET_AVG_REVENUE_RID_SELECTED: 'SET_AVG_REVENUE_RID_SELECTED',
    SET_AVG_REVENUE_RID_OPTION: 'SET_AVG_REVENUE_RID_OPTION',
    SET_AVG_REVENUE_GAME: 'SET_AVG_REVENUE_GAME',
    GET_AVG_REVENUE_COUNTRIES: 'GET_AVG_REVENUE_COUNTRIES',
    SET_AVG_REVENUE_COUNTRIES: 'SET_AVG_REVENUE_COUNTRIES',
    GET_AVG_REVENUE_RID: 'GET_AVG_REVENUE_RID',
    SET_AVG_REVENUE_RID: 'SET_AVG_REVENUE_RID',
    SET_AVG_REVENUE_DATA: 'SET_AVG_REVENUE_DATA',
    SET_AVG_REVENUE_LOADING: 'SET_AVG_REVENUE_LOADING',
    SET_ALL_PM_FOR_AVG_REVENUE_SELECT: 'SET_ALL_PM_FOR_AVG_REVENUE_SELECT',
    SET_AVG_REVENUE_DATA_BY_GAME: 'SET_AVG_REVENUE_DATA_BY_GAME',
    SET_AVG_REVENUE_DATA_BY_COUNTRY: 'SET_AVG_REVENUE_DATA_BY_COUNTRY',
    SET_AVG_REVENUE_DATA_BY_RID: 'SET_AVG_REVENUE_DATA_BY_RID',
    SET_AVG_REVENUE_DATA_BY_GAME_PAID: 'SET_AVG_REVENUE_DATA_BY_GAME_PAID',
    SET_AVG_REVENUE_DATA_BY_COUNTRY_PAID: 'SET_AVG_REVENUE_DATA_BY_COUNTRY_PAID',
    SET_AVG_REVENUE_DATA_BY_RID_PAID: 'SET_AVG_REVENUE_DATA_BY_RID_PAID',
    SET_AVG_REVENUE_DATE_RANGE: 'SET_AVG_REVENUE_DATE_RANGE',
    SET_PAID_PMID_CSV_DATA: 'SET_PAID_PMID_CSV_DATA',
    SET_ALL_PMID_CSV_DATA: 'SET_ALL_PMID_CSV_DATA',
    SET_KPI_SALES_DATE_RANGE: 'SET_KPI_SALES_DATE_RANGE',
    GET_KPI_SALES_DATA: 'GET_KPI_SALES_DATA',
    SET_KPI_SALES_DATA: 'SET_KPI_SALES_DATA',
    SET_KPI_SALES_DATA_SIZE_SELECTED: 'SET_KPI_SALES_DATA_SIZE_SELECTED',
}

const DATA_LOAD = {
    GET_DATA_LOADED: 'GET_DATA_LOADED',
    SET_DATA_LOADED: 'SET_DATA_LOADED',
}

const NFTDEP = {
    SET_DATA_LOADING: 'SET_DATA_LOADING',
    SET_FOCUS_TAB_INDEX: 'SET_FOCUS_TAB_INDEX',
    SET_DATA_LOADING_FOR_BY: 'SET_DATA_LOADING_FOR_BY',
    GET_TODAYS_SALES_DATA: 'GET_TODAYS_SALES_DATA',
    SET_TODAYS_SALES_DATA: 'SET_TODAYS_SALES_DATA',
    GET_SALES_OVERVIEW_DATA: 'GET_SALES_OVERVIEW_DATA',
    SET_SALES_OVERVIEW_DATA: 'SET_SALES_OVERVIEW_DATA',
    GET_SALES_OVERVIEW_DATA_BY_GAME: 'GET_SALES_OVERVIEW_DATA_BY_GAME',
    SET_SALES_OVERVIEW_DATA_BY_GAME: 'SET_SALES_OVERVIEW_DATA_BY_GAME',
    SET_ALL_DEP_SALES_DATA: 'SET_ALL_DEP_SALES_DATA',
    SET_ALL_DEP_FEES_DATA: 'SET_ALL_DEP_FEES_DATA',
    SET_ALL_TX_COUNT_DATA: 'SET_ALL_TX_COUNT_DATA',
    SET_DATA_BY_COUNTRY: 'SET_DATA_BY_COUNTRY',
    SET_DATA_BY_PMID: 'SET_DATA_BY_PMID',
    SET_DATA_BY_GAME: 'SET_DATA_BY_GAME',
    SET_DATA_BY_RID: 'SET_DATA_BY_RID',
    SET_ACTIVE_WALLET_DATA: 'SET_ACTIVE_WALLET_DATA',
    SET_TREND_DATE_RANGE: 'SET_TREND_DATE_RANGE',
    SET_TREND_OPTION: 'SET_TREND_OPTION',
    SET_TREND_OPTION_SELECTED: 'SET_TREND_OPTION_SELECTED',
    GET_TREND_PAGE_DATA: 'GET_TREND_PAGE_DATA',
    SET_TREND_PAGE_DATA_COUNT: 'SET_TREND_PAGE_DATA_COUNT',
    SET_TREND_PAGE_DATA_AMOUNT: 'SET_TREND_PAGE_DATA_AMOUNT',
    SET_TREND_PAGE_DATA_SUMMARY: 'SET_TREND_PAGE_DATA_SUMMARY',
    SET_TX_UNIQUE_NEW_DATA: 'SET_TX_UNIQUE_NEW_DATA',
    SET_TX_STORE_UNIQUE_NEW_DATA: 'SET_TX_STORE_UNIQUE_NEW_DATA',
    SET_DATA_TX_BLIND: 'SET_DATA_TX_BLIND',
    SET_DATA_TX_SNAP: 'SET_DATA_TX_SNAP',
    SET_TX_AUCTION_STATS_DATA: 'SET_TX_AUCTION_STATS_DATA',
    SET_TX_STORE_STATS_DATA: 'SET_TX_STORE_STATS_DATA',
    SET_TX_FIAT_STATS_DATA: 'SET_TX_FIAT_STATS_DATA',
    SET_TX_FIRST_TIME_PURCHASE_STATS_DATA: 'SET_TX_FIRST_TIME_PURCHASE_STATS_DATA',
    SET_DATA_STAKING_OV_COUNT: 'SET_DATA_STAKING_OV_COUNT',
    SET_DATA_STAKING_OV_DEP: 'SET_DATA_STAKING_OV_DEP',
    SET_OVERVIEW_TAB: 'SET_OVERVIEW_TAB',
    GET_OVERVIEW_DATA_BY_COUNTRY: 'GET_OVERVIEW_DATA_BY_COUNTRY',
    GET_OVERVIEW_DATA_BY_PMID: 'GET_OVERVIEW_DATA_BY_PMID',
    GET_OVERVIEW_DATA_BY_GAME: 'GET_OVERVIEW_DATA_BY_GAME',
    GET_OVERVIEW_DATA_BY_CAMPAIGN: 'GET_OVERVIEW_DATA_BY_CAMPAIGN',
    GET_OVERVIEW_GAME_DATA_BY_COUNTRY: 'GET_OVERVIEW_GAME_DATA_BY_COUNTRY',
    SET_TREND_DATA_LOADING: 'SET_TREND_DATA_LOADING',
    SET_TREND_DATA_COUNTRY_LOADING: 'SET_TREND_DATA_COUNTRY_LOADING',
    GET_TREND_DATA_COUNTRY: 'GET_TREND_DATA_COUNTRY',
    SET_TREND_DATA_COUNTRY_OPTION: 'SET_TREND_DATA_COUNTRY_OPTION',
    SET_TREND_DATA_COUNTRY_SELECTED: 'SET_TREND_DATA_COUNTRY_SELECTED',
    GET_TREND_DATA_GAMES: 'GET_TREND_DATA_GAMES',
    SET_TREND_DATA_GAME_OPTION: 'SET_TREND_DATA_GAME_OPTION',
    SET_TREND_DATA_GAME_SELECTED: 'SET_TREND_DATA_GAME_SELECTED',
    SET_TX_HISTORY_SEARCH_TERM: 'SET_TX_HISTORY_SEARCH_TERM',
    SET_TX_HISTORY_SEARCH_LOADING: 'SET_TX_HISTORY_SEARCH_LOADING',
    GET_NFT_ASSET_SEARCH: 'GET_NFT_ASSET_SEARCH',
    SET_TX_HISTORY_SEARCH_OPTION: 'SET_TX_HISTORY_SEARCH_OPTION',
    GET_NFT_TX_HISTORY_DATA: 'GET_NFT_TX_HISTORY_DATA',
    GET_NFT_TX_HISTORY_DATA_BY_TERM: 'GET_NFT_TX_HISTORY_DATA_BY_TERM',
    SET_TX_HISTORY_DATA_LOADING: 'SET_TX_HISTORY_DATA_LOADING',
    SET_TX_HISTORY_DATA: 'SET_TX_HISTORY_DATA',
    SET_TX_HISTORY_STAT_DATA: 'SET_TX_HISTORY_STAT_DATA',
    GET_NFT_LOW_TX_HISTORY_DATA: 'GET_NFT_LOW_TX_HISTORY_DATA',
    GET_NFT_HOLDERS_DATA: 'GET_NFT_HOLDERS_DATA',
    SET_NFT_HOLDERS_DATA: 'SET_NFT_HOLDERS_DATA',
    SET_NFT_HOLDERS_DATA_LOADING: 'SET_NFT_HOLDERS_DATA_LOADING',
    SET_NFT_HOLDERS_TOTAL_COUNT: 'SET_NFT_HOLDERS_TOTAL_COUNT',
    SET_NFT_HOLDERS_PAGE_NUMBER: 'SET_NFT_HOLDERS_PAGE_NUMBER',
    SET_NFT_HOLDERS_STATS_DATA: 'SET_NFT_HOLDERS_STATS_DATA',
    SET_LOW_TX_HISTORY_DATA: 'SET_LOW_TX_HISTORY_DATA',
    SET_LOW_TX_HISTORY_STAT_DATA: 'SET_LOW_TX_HISTORY_STAT_DATA',
    SET_TX_HISTORY_DATA_FILTER: 'SET_TX_HISTORY_DATA_FILTER',
    SET_FILTER: 'SET_FILTER',
    SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
    SET_STAKING_DATA_LOADING: 'SET_STAKING_DATA_LOADING',
    SET_STAKING_TOTAL_DATA: 'SET_STAKING_TOTAL_DATA',
    SET_STAKING_STAT_DATA: 'SET_STAKING_STAT_DATA',
    GET_STAKING_TOTAL_DATA: 'GET_STAKING_TOTAL_DATA',
    GET_STAKING_STAT_DATA: 'GET_STAKING_STAT_DATA',
    SET_NFT_OWN_TREND_DATE_RANGE: 'SET_NFT_OWN_TREND_DATE_RANGE',
    SET_NFT_OWN_TREND_OPTION: 'SET_NFT_OWN_TREND_OPTION',
    SET_NFT_OWN_TREND_OPTION_SELECTED: 'SET_NFT_OWN_TREND_OPTION_SELECTED',
    SET_NFT_OWN_TREND_DATA_LOADING: 'SET_NFT_OWN_TREND_DATA_LOADING',
    SET_NFT_OWN_TREND_DATA: 'SET_NFT_OWN_TREND_DATA',
    GET_NFT_OWN_TREND_DATA: 'GET_NFT_OWN_TREND_DATA',
    GET_TREND_TX_KINDS: 'GET_TREND_TX_KINDS',
    SET_LOW_TX_DEP_RANGE: 'SET_LOW_TX_DEP_RANGE',
    GET_LOW_TX_CSV_DATA: 'GET_LOW_TX_CSV_DATA',
    SET_LOW_TX_CSV_DATA: 'SET_LOW_TX_CSV_DATA',
    SET_LOW_CSV_DOWNLOADER: 'SET_LOW_CSV_DOWNLOADER',
    GET_NFT_TX_CSV_DATA: 'GET_NFT_TX_CSV_DATA',
    SET_NFT_TX_CSV_DATA: 'SET_NFT_TX_CSV_DATA',
    SET_NFT_CSV_DOWNLOADER: 'SET_NFT_CSV_DOWNLOADER',
    GET_WALLET_BALANCE_DATA: 'GET_WALLET_BALANCE_DATA',
    SET_WALLET_BALANCE_DATA: 'SET_WALLET_BALANCE_DATA',
}

const LOSTARCHIVE = {
    SET_LOSTARCHIVE_NFT_TREND_DATA_LOADING: 'SET_LOSTARCHIVE_NFT_TREND_DATA_LOADING',
    SET_LOSTARCHIVE_NFT_TREND_DATE_RANGE: 'SET_LOSTARCHIVE_NFT_TREND_DATE_RANGE',
    GET_LOSTARCHIVE_NFT_TREND_PAGE_DATA: 'GET_LOSTARCHIVE_NFT_TREND_PAGE_DATA',
    SET_LOSTARCHIVE_NFT_TREND_PAGE_DATA: 'SET_LOSTARCHIVE_NFT_TREND_PAGE_DATA',
    SET_LOSTARCHIVE_NFT_TREND_COUNT_AUCTION: 'SET_LOSTARCHIVE_NFT_TREND_COUNT_AUCTION',
    SET_LOSTARCHIVE_NFT_TREND_AMOUNT_AUCTION: 'SET_LOSTARCHIVE_NFT_TREND_AMOUNT_AUCTION',
    SET_LOSTARCHIVE_NFT_TREND_COUNT_STORE: 'SET_LOSTARCHIVE_NFT_TREND_COUNT_STORE',
    SET_LOSTARCHIVE_NFT_TREND_AMOUNT_STORE: 'SET_LOSTARCHIVE_NFT_TREND_AMOUNT_STORE',
    SET_LOSTARCHIVE_NFT_TREND_COUNT_STORE_FIAT: 'SET_LOSTARCHIVE_NFT_TREND_COUNT_STORE_FIAT',
    SET_LOSTARCHIVE_NFT_TREND_AMOUNT_STORE_FIAT: 'SET_LOSTARCHIVE_NFT_TREND_AMOUNT_STORE_FIAT',
    SET_LOSTARCHIVE_NFT_TREND_AUCTION_ALL: 'SET_LOSTARCHIVE_NFT_TREND_AUCTION_ALL',
    SET_LOSTARCHIVE_NFT_TREND_STORE_ALL: 'SET_LOSTARCHIVE_NFT_TREND_STORE_ALL',
    SET_LOSTARCHIVE_NFT_TREND_STORE_ALL_FIAT: 'SET_LOSTARCHIVE_NFT_TREND_STORE_ALL_FIAT',
}

const LUCKYFARMER = {
    SET_LUCKYFARMER_NFT_TREND_DATA_LOADING: 'SET_LUCKYFARMER_NFT_TREND_DATA_LOADING',
    SET_LUCKYFARMER_NFT_TREND_DATE_RANGE: 'SET_LUCKYFARMER_NFT_TREND_DATE_RANGE',
    GET_LUCKYFARMER_NFT_TREND_PAGE_DATA: 'GET_LUCKYFARMER_NFT_TREND_PAGE_DATA',
    SET_LUCKYFARMER_NFT_TREND_PAGE_DATA: 'SET_LUCKYFARMER_NFT_TREND_PAGE_DATA',
    SET_LUCKYFARMER_NFT_TREND_COUNT_AUCTION: 'SET_LUCKYFARMER_NFT_TREND_COUNT_AUCTION',
    SET_LUCKYFARMER_NFT_TREND_AMOUNT_AUCTION: 'SET_LUCKYFARMER_NFT_TREND_AMOUNT_AUCTION',
    SET_LUCKYFARMER_NFT_TREND_COUNT_STORE: 'SET_LUCKYFARMER_NFT_TREND_COUNT_STORE',
    SET_LUCKYFARMER_NFT_TREND_AMOUNT_STORE: 'SET_LUCKYFARMER_NFT_TREND_AMOUNT_STORE',
    SET_LUCKYFARMER_NFT_TREND_COUNT_STORE_FIAT: 'SET_LUCKYFARMER_NFT_TREND_COUNT_STORE_FIAT',
    SET_LUCKYFARMER_NFT_TREND_AMOUNT_STORE_FIAT: 'SET_LUCKYFARMER_NFT_TREND_AMOUNT_STORE_FIAT',
    SET_LUCKYFARMER_GAME_TREND_DATA_LOADING: 'SET_LUCKYFARMER_GAME_TREND_DATA_LOADING',
    SET_LUCKYFARMER_GAME_TREND_DATE_RANGE: 'SET_LUCKYFARMER_GAME_TREND_DATE_RANGE',
    GET_LUCKYFARMER_GAME_TREND_PAGE_DATA: 'GET_LUCKYFARMER_GAME_TREND_PAGE_DATA',
    SET_LUCKYFARMER_GAME_TREND_PAGE_DATA: 'SET_LUCKYFARMER_GAME_TREND_PAGE_DATA',
    SET_LUCKYFARMER_GAME_TREND_STAMINA: 'SET_LUCKYFARMER_GAME_TREND_STAMINA',
    SET_LUCKYFARMER_GAME_TREND_LAND: 'SET_LUCKYFARMER_GAME_TREND_LAND',
    SET_LUCKYFARMER_GAME_TREND_SHOP: 'SET_LUCKYFARMER_GAME_TREND_SHOP',
    SET_LUCKYFARMER_GAME_TREND_GACHA: 'SET_LUCKYFARMER_GAME_TREND_GACHA',
    SET_LUCKYFARMER_GAME_TREND_UNIQUE: 'SET_LUCKYFARMER_GAME_TREND_UNIQUE',
    SET_LUCKYFARMER_NFT_TREND_AUCTION_ALL: 'SET_LUCKYFARMER_NFT_TREND_AUCTION_ALL',
    SET_LUCKYFARMER_NFT_TREND_STORE_ALL: 'SET_LUCKYFARMER_NFT_TREND_STORE_ALL',
    SET_LUCKYFARMER_NFT_TREND_STORE_ALL_FIAT: 'SET_LUCKYFARMER_NFT_TREND_STORE_ALL_FIAT',
}

const COOKINBURGER = {
    SET_COOKINBURGER_NFT_TREND_DATA_LOADING: 'SET_COOKINBURGER_NFT_TREND_DATA_LOADING',
    SET_COOKINBURGER_NFT_TREND_DATE_RANGE: 'SET_COOKINBURGER_NFT_TREND_DATE_RANGE',
    GET_COOKINBURGER_NFT_TREND_PAGE_DATA: 'GET_COOKINBURGER_NFT_TREND_PAGE_DATA',
    SET_COOKINBURGER_NFT_TREND_PAGE_DATA: 'SET_COOKINBURGER_NFT_TREND_PAGE_DATA',
    SET_COOKINBURGER_NFT_TREND_COUNT_AUCTION: 'SET_COOKINBURGER_NFT_TREND_COUNT_AUCTION',
    SET_COOKINBURGER_NFT_TREND_AMOUNT_AUCTION: 'SET_COOKINBURGER_NFT_TREND_AMOUNT_AUCTION',
    SET_COOKINBURGER_NFT_TREND_COUNT_STORE: 'SET_COOKINBURGER_NFT_TREND_COUNT_STORE',
    SET_COOKINBURGER_NFT_TREND_AMOUNT_STORE: 'SET_COOKINBURGER_NFT_TREND_AMOUNT_STORE',
    SET_COOKINBURGER_NFT_TREND_COUNT_STORE_FIAT: 'SET_COOKINBURGER_NFT_TREND_COUNT_STORE_FIAT',
    SET_COOKINBURGER_NFT_TREND_AMOUNT_STORE_FIAT: 'SET_COOKINBURGER_NFT_TREND_AMOUNT_STORE_FIAT',
    SET_COOKINBURGER_NFT_TREND_AUCTION_ALL: 'SET_COOKINBURGER_NFT_TREND_AUCTION_ALL',
    SET_COOKINBURGER_NFT_TREND_STORE_ALL: 'SET_COOKINBURGER_NFT_TREND_STORE_ALL',
    SET_COOKINBURGER_NFT_TREND_STORE_ALL_FIAT: 'SET_COOKINBURGER_NFT_TREND_STORE_ALL_FIAT',
    GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA_CB: 'GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA_CB',
    SET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA_CB: 'SET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA_CB',
    SET_NUM_OWNER_CB: 'SET_NUM_OWNER_CB',
    SET_NUM_SCHOLAR_CB: 'SET_NUM_SCHOLAR_CB',
    SET_SCHOLAR_TOTAL_DEP_COUNT_CB: 'SET_SCHOLAR_TOTAL_DEP_COUNT_CB',
    SET_OVERVIEW_OWNER_DATA_CB: "SET_OVERVIEW_OWNER_DATA_CB",
    SET_OVERVIEW_SCHOLAR_DATA_CB: 'SET_OVERVIEW_SCHOLAR_DATA_CB',
    SET_DEAP_CHECK_AMOUNT_CB: 'SET_DEAP_CHECK_AMOUNT_CB',
    SET_PER_OWNER_DATA_CB: 'SET_PER_OWNER_DATA_CB',
    SET_COOKINBURGER_GAME_TREND_DATA_SALES: 'SET_COOKINBURGER_GAME_TREND_DATA_SALES',
    SET_COOKINBURGER_GAME_TREND_UNIQUE: 'SET_COOKINBURGER_GAME_TREND_UNIQUE',
    SET_COOKINBURGER_GAME_TREND_AMOUNT: 'SET_COOKINBURGER_GAME_TREND_AMOUNT',
    SET_COOKINBURGER_GAME_TREND_DATA_UNIQUE_COUNT: 'SET_COOKINBURGER_GAME_TREND_DATA_UNIQUE_COUNT',
    SET_COOKINBURGER_GAME_TREND_DATA_LOADING: 'SET_COOKINBURGER_GAME_TREND_DATA_LOADING',
    SET_COOKINBURGER_GAME_TREND_DATE_RANGE: 'SET_COOKINBURGER_GAME_TREND_DATE_RANGE',
    GET_COOKINBURGER_GAME_TREND_PAGE_DATA: 'GET_COOKINBURGER_GAME_TREND_PAGE_DATA',
    SET_COOKINBURGER_GAME_TREND_PAGE_DATA: 'SET_COOKINBURGER_GAME_TREND_PAGE_DATA',
}

const DRAGONRAMEN = {
    SET_DRAGONRAMEN_NFT_TREND_DATA_LOADING: 'SET_DRAGONRAMEN_NFT_TREND_DATA_LOADING',
    SET_DRAGONRAMEN_NFT_TREND_DATE_RANGE: 'SET_DRAGONRAMEN_NFT_TREND_DATE_RANGE',
    GET_DRAGONRAMEN_NFT_TREND_PAGE_DATA: 'GET_DRAGONRAMEN_NFT_TREND_PAGE_DATA',
    SET_DRAGONRAMEN_NFT_TREND_PAGE_DATA: 'SET_DRAGONRAMEN_NFT_TREND_PAGE_DATA',
    SET_DRAGONRAMEN_NFT_TREND_COUNT_AUCTION: 'SET_DRAGONRAMEN_NFT_TREND_COUNT_AUCTION',
    SET_DRAGONRAMEN_NFT_TREND_AMOUNT_AUCTION: 'SET_DRAGONRAMEN_NFT_TREND_AMOUNT_AUCTION',
    SET_DRAGONRAMEN_NFT_TREND_COUNT_STORE: 'SET_DRAGONRAMEN_NFT_TREND_COUNT_STORE',
    SET_DRAGONRAMEN_NFT_TREND_AMOUNT_STORE: 'SET_DRAGONRAMEN_NFT_TREND_AMOUNT_STORE',
    SET_DRAGONRAMEN_NFT_TREND_COUNT_STORE_FIAT: 'SET_DRAGONRAMEN_NFT_TREND_COUNT_STORE_FIAT',
    SET_DRAGONRAMEN_NFT_TREND_AMOUNT_STORE_FIAT: 'SET_DRAGONRAMEN_NFT_TREND_AMOUNT_STORE_FIAT',
    SET_DRAGONRAMEN_NFT_TREND_DATA_COUNT_STORE_FIAT: 'SET_DRAGONRAMEN_NFT_TREND_DATA_COUNT_STORE_FIAT',
    SET_DRAGONRAMEN_NFT_TREND_DATA_AMOUNT_STORE_FIAT: 'SET_DRAGONRAMEN_NFT_TREND_DATA_AMOUNT_STORE_FIAT',
    SET_DRAGONRAMEN_NFT_TREND_AUCTION_ALL: 'SET_DRAGONRAMEN_NFT_TREND_AUCTION_ALL',
    SET_DRAGONRAMEN_NFT_TREND_STORE_ALL: 'SET_DRAGONRAMEN_NFT_TREND_STORE_ALL',
    SET_DRAGONRAMEN_NFT_TREND_STORE_ALL_FIAT: 'SET_DRAGONRAMEN_NFT_TREND_STORE_ALL_FIAT',
}

const GRAFITTIRACER = {
    SET_GRAFITTIRACER_NFT_TREND_DATA_LOADING: 'SET_GRAFITTIRACER_NFT_TREND_DATA_LOADING',
    SET_GRAFITTIRACER_NFT_TREND_DATE_RANGE: 'SET_GRAFITTIRACER_NFT_TREND_DATE_RANGE',
    GET_GRAFITTIRACER_NFT_TREND_PAGE_DATA: 'GET_GRAFITTIRACER_NFT_TREND_PAGE_DATA',
    SET_GRAFITTIRACER_NFT_TREND_PAGE_DATA: 'SET_GRAFITTIRACER_NFT_TREND_PAGE_DATA',
    SET_GRAFITTIRACER_NFT_TREND_COUNT_AUCTION: 'SET_GRAFITTIRACER_NFT_TREND_COUNT_AUCTION',
    SET_GRAFITTIRACER_NFT_TREND_AMOUNT_AUCTION: 'SET_GRAFITTIRACER_NFT_TREND_AMOUNT_AUCTION',
    SET_GRAFITTIRACER_NFT_TREND_COUNT_STORE: 'SET_GRAFITTIRACER_NFT_TREND_COUNT_STORE',
    SET_GRAFITTIRACER_NFT_TREND_AMOUNT_STORE: 'SET_GRAFITTIRACER_NFT_TREND_AMOUNT_STORE',
    SET_GRAFITTIRACER_NFT_TREND_COUNT_STORE_FIAT: 'SET_GRAFITTIRACER_NFT_TREND_COUNT_STORE_FIAT',
    SET_GRAFITTIRACER_NFT_TREND_AMOUNT_STORE_FIAT: 'SET_GRAFITTIRACER_NFT_TREND_AMOUNT_STORE_FIAT',
    SET_GRAFITTIRACER_GAME_TREND_DATA_LOADING: 'SET_GRAFITTIRACER_GAME_TREND_DATA_LOADING',
    SET_GRAFITTIRACER_GAME_TREND_DATE_RANGE: 'SET_GRAFITTIRACER_GAME_TREND_DATE_RANGE',
    GET_GRAFITTIRACER_GAME_TREND_PAGE_DATA: 'GET_GRAFITTIRACER_GAME_TREND_PAGE_DATA',
    SET_GRAFITTIRACER_GAME_TREND_DEP_USE: 'SET_GRAFITTIRACER_GAME_TREND_DEP_USE',
    SET_GRAFITTIRACER_GAME_TREND_DEP_GET: 'SET_GRAFITTIRACER_GAME_TREND_DEP_GET',
    SET_GRAFITTIRACER_GAME_TREND_UNIQUE_COUNT: 'SET_GRAFITTIRACER_GAME_TREND_UNIQUE_COUNT',
    SET_GRAFITTIRACER_GAME_TREND_UNIQUE_COUNT_FIAT: 'SET_GRAFITTIRACER_GAME_TREND_UNIQUE_COUNT_FIAT',
    SET_GRAFITTIRACER_NFT_TREND_STORE_ALL: 'SET_GRAFITTIRACER_NFT_TREND_STORE_ALL',
    SET_GRAFITTIRACER_NFT_TREND_STORE_ALL_FIAT: 'SET_GRAFITTIRACER_NFT_TREND_STORE_ALL_FIAt',
    SET_GRAFITTIRACER_NFT_TREND_AUCTION_ALL: 'SET_GRAFITTIRACER_NFT_TREND_AUCTION_ALL',
    SET_GRAFITTIRACER_DEP_USE_COUNT_DETAILS: 'SET_GRAFITTIRACER_DEP_USE_COUNT_DETAILS',
    SET_GRAFITTIRACER_DEP_USE_AMOUNT_DETAILS: 'SET_GRAFITTIRACER_DEP_USE_AMOUNT_DETAILS',
    SET_GRAFITTIRACER_DEP_USE_COUNT_DETAILS_FIAT: 'SET_GRAFITTIRACER_DEP_USE_COUNT_DETAILS_FIAT',
    SET_GRAFITTIRACER_DEP_USE_AMOUNT_DETAILS_FIAT: 'SET_GRAFITTIRACER_DEP_USE_AMOUNT_DETAILS_FIAT',
}

const KAMUIVERSE = {
    SET_KAMUIVERSE_NFT_TREND_DATA_LOADING: 'SET_KAMUIVERSE_NFT_TREND_DATA_LOADING',
    SET_KAMUIVERSE_NFT_TREND_DATE_RANGE: 'SET_KAMUIVERSE_NFT_TREND_DATE_RANGE',
    GET_KAMUIVERSE_NFT_TREND_PAGE_DATA: 'GET_KAMUIVERSE_NFT_TREND_PAGE_DATA',
    SET_KAMUIVERSE_NFT_TREND_PAGE_DATA: 'SET_KAMUIVERSE_NFT_TREND_PAGE_DATA',
    SET_KAMUIVERSE_NFT_TREND_COUNT_AUCTION: 'SET_KAMUIVERSE_NFT_TREND_COUNT_AUCTION',
    SET_KAMUIVERSE_NFT_TREND_AMOUNT_AUCTION: 'SET_KAMUIVERSE_NFT_TREND_AMOUNT_AUCTION',
    SET_KAMUIVERSE_NFT_TREND_COUNT_STORE: 'SET_KAMUIVERSE_NFT_TREND_COUNT_STORE',
    SET_KAMUIVERSE_NFT_TREND_AMOUNT_STORE: 'SET_KAMUIVERSE_NFT_TREND_AMOUNT_STORE',
}

const JTCB = {
    SET_SCHOLAR_DATA_LOADING: 'SET_SCHOLAR_DATA_LOADING',
    GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA: 'GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA',
    SET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA: 'SET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA',
    SET_OVERVIEW_OWNER_DATA: 'SET_OVERVIEW_OWNER_DATA',
    SET_OVERVIEW_SCHOLAR_DATA: 'SET_OVERVIEW_SCHOLAR_DATA',
    SET_PER_OWNER_DATA: 'SET_PER_OWNER_DATA',
    SET_LENDING_COUNT: 'SET_LENDING_COUNT',
    SET_DEAP_CHECK_AMOUNT: 'SET_DEAP_CHECK_AMOUNT',
    SET_UNIQUE_OWNER_COUNT_BY_DATE: 'SET_UNIQUE_OWNER_COUNT_BY_DATE',
    SET_UNIQUE_SCHOLAR_COUNT_BY_DATE: 'SET_UNIQUE_SCHOLAR_COUNT_BY_DATE',
    SET_OWNER_BY_COUNTRY: 'SET_OWNER_BY_COUNTRY',
    SET_SCHOLAR_BY_COUNTRY: 'SET_SCHOLAR_BY_COUNTRY',
    SET_TOTAL_AMULET_BY_DATE: 'SET_TOTAL_AMULET_BY_DATE',
    SET_TOTAL_DEP_BY_DATE: 'SET_TOTAL_DEP_BY_DATE',
    SET_TOTAL_SCHOLAR_DEP_BY_DATE: 'SET_TOTAL_SCHOLAR_DEP_BY_DATE',
    SET_COUNT_SCHOLAR_BY_OWNER: 'SET_COUNT_SCHOLAR_BY_OWNER',
    SET_COUNT_AMULET_BY_OWNER: 'SET_COUNT_AMULET_BY_OWNER',
    SET_COUNT_DEP_BY_OWNER: 'SET_COUNT_DEP_BY_OWNER',
    SET_COUNT_DEP_BY_SCHOLAR: 'SET_COUNT_DEP_BY_SCHOLAR',
    SET_NUM_OWNER: 'SET_NUM_OWNER',
    SET_NUM_SCHOLAR: 'SET_NUM_SCHOLAR',
    SET_SCHOLAR_TREND_DATA_LOADING: 'SET_SCHOLAR_TREND_DATA_LOADING',
    GET_JTCB_SCHOLAR_TREND_DATA: 'GET_JTCB_SCHOLAR_TREND_DATA',
    SET_JTCB_SCHOLAR_TREND_DATA: 'SET_JTCB_SCHOLAR_TREND_DATA',
    SET_SCHOLAR_AMULET_DATA_LOADING: 'SET_SCHOLAR_AMULET_DATA_LOADING',
    GET_JTCB_SCHOLAR_AMULET_DATA: 'GET_JTCB_SCHOLAR_AMULET_DATA',
    SET_JTCB_SCHOLAR_AMULET_DATA: 'SET_JTCB_SCHOLAR_AMULET_DATA',
    SET_DATA_LOADING_AMULET_JOB: 'SET_DATA_LOADING_AMULET_JOB',
    GET_JTCB_SCHOLAR_AMULET_DATA_BY_JOB: 'GET_JTCB_SCHOLAR_AMULET_DATA_BY_JOB',
    SET_JTCB_SCHOLAR_AMULET_DATA_BY_JOB: 'SET_JTCB_SCHOLAR_AMULET_DATA_BY_JOB',
    SET_AMULET_SELECTED: 'SET_AMULET_SELECTED',
    GET_JTCB_SCHOLAR_DATA_BY_GUILD: 'GET_JTCB_SCHOLAR_DATA_BY_GUILD',
    SET_SCHOLAR_GUILD_DATA_LOADING: 'SET_SCHOLAR_GUILD_DATA_LOADING',
    SET_KOGI_GUILD_DATA: 'SET_KOGI_GUILD_DATA',
    SET_KYOGI_GUILD_DATA: 'SET_KYOGI_GUILD_DATA',
    SET_JTCB_NFT_TREND_DATE_RANGE: 'SET_JTCB_NFT_TREND_DATE_RANGE',
    SET_JTCB_NFT_TREND_DATA_LOADING: 'SET_JTCB_NFT_TREND_DATA_LOADING',
    GET_JTCB_NFT_TREND_PAGE_DATA: 'GET_JTCB_NFT_TREND_PAGE_DATA',
    SET_JTCB_NFT_TREND_PAGE_DATA: 'SET_JTCB_NFT_TREND_PAGE_DATA',
    SET_JTCB_NFT_TREND_KIND_SELECTED: 'SET_JTCB_NFT_TREND_KIND_SELECTED',
    SET_JTCB_NFT_TREND_RARE_SELECTED: 'SET_JTCB_NFT_TREND_RARE_SELECTED',
    SET_JTCB_NFT_TREND_DATA_COUNT_AUCTION: 'SET_JTCB_NFT_TREND_DATA_COUNT_AUCTION',
    SET_JTCB_NFT_TREND_DATA_AMOUNT_AUCTION: 'SET_JTCB_NFT_TREND_DATA_AMOUNT_AUCTION',
    SET_JTCB_NFT_TREND_DATA_COUNT_STORE: 'SET_JTCB_NFT_TREND_DATA_COUNT_STORE',
    SET_JTCB_NFT_TREND_DATA_AMOUNT_STORE: 'SET_JTCB_NFT_TREND_DATA_AMOUNT_STORE',
    SET_JTCB_NFT_TREND_DATA_COUNT_STORE_FIAT: 'SET_JTCB_NFT_TREND_DATA_COUNT_STORE_FIAT',
    SET_JTCB_NFT_TREND_DATA_AMOUNT_STORE_FIAT: 'SET_JTCB_NFT_TREND_DATA_AMOUNT_STORE_FIAT',
    SET_JTCB_NFT_TREND_AUCTION_ALL: 'SET_JTCB_NFT_TREND_AUCTION_ALL',
    SET_JTCB_NFT_TREND_STORE_ALL: 'SET_JTCB_NFT_TREND_STORE_ALL',
    SET_JTCB_NFT_TREND_STORE_ALL_FIAT: 'SET_JTCB_NFT_TREND_STORE_ALL_FIAT',
}

const SOULFUSERS = {
    SET_SOULFUSERS_NFT_TREND_DATA_LOADING: 'SET_SOULFUSERS_NFT_TREND_DATA_LOADING',
    SET_SOULFUSERS_NFT_TREND_DATE_RANGE: 'SET_SOULFUSERS_NFT_TREND_DATE_RANGE',
    GET_SOULFUSERS_NFT_TREND_PAGE_DATA: 'GET_SOULFUSERS_NFT_TREND_PAGE_DATA',
    SET_SOULFUSERS_NFT_TREND_PAGE_DATA: 'SET_SOULFUSERS_NFT_TREND_PAGE_DATA',
    SET_SOULFUSERS_NFT_TREND_COUNT_AUCTION: 'SET_SOULFUSERS_NFT_TREND_COUNT_AUCTION',
    SET_SOULFUSERS_NFT_TREND_AMOUNT_AUCTION: 'SET_SOULFUSERS_NFT_TREND_AMOUNT_AUCTION',
    SET_SOULFUSERS_NFT_TREND_COUNT_STORE: 'SET_SOULFUSERS_NFT_TREND_COUNT_STORE',
    SET_SOULFUSERS_NFT_TREND_AMOUNT_STORE: 'SET_SOULFUSERS_NFT_TREND_AMOUNT_STORE',
    SET_SOULFUSERS_NFT_TREND_STORE_ALL_FIAT: 'SET_SOULFUSERS_NFT_TREND_STORE_ALL_FIAT',
    SET_SOULFUSERS_NFT_TREND_COUNT_STORE_FIAT: 'SET_SOULFUSERS_NFT_TREND_COUNT_STORE_FIAT',
    SET_SOULFUSERS_NFT_TREND_AMOUNT_STORE_FIAT: 'SET_SOULFUSERS_NFT_TREND_AMOUNT_STORE_FIAT',
    SET_SOULFUSERS_NFT_TREND_AUCTION_ALL: 'SET_SOULFUSERS_NFT_TREND_AUCTION_ALL',
    SET_SOULFUSERS_NFT_TREND_STORE_ALL: 'SET_SOULFUSERS_NFT_TREND_STORE_ALL',
}

const PICTREE = {
    GET_HOME_USER: 'GET_HOME_USER',
    SET_HOME_USER: 'SET_HOME_USER',
    GET_HOME_CHECKIN: 'GET_HOME_CHECKIN',
    SET_HOME_CHECKIN: 'SET_HOME_CHECKIN',
    GET_REGIST_TREND: 'GET_REGIST_TREND',
    SET_REGIST_TREND: 'SET_REGIST_TREND',
    GET_REGIST_TABLE: 'GET_REGIST_TABLE',
    SET_REGIST_TABLE: 'SET_REGIST_TABLE',
    GET_SALES_OVERVIEW: 'GET_SALES_OVERVIEW',
    SET_SALES_OVERVIEW: 'SET_SALES_OVERVIEW',
    GET_SALES_TREND: 'GET_SALES_TREND',
    SET_SALES_TREND: 'SET_SALES_TREND',
    GET_SALES_TABLE: 'GET_SALES_TABLE',
    SET_SALES_TABLE: 'SET_SALES_TABLE',
    GET_POINT_OVERVIEW: 'GET_POINT_OVERVIEW',
    SET_POINT_OVERVIEW: 'SET_POINT_OVERVIEW',
    GET_POINT_TREND: 'GET_POINT_TREND',
    SET_POINT_TREND: 'SET_POINT_TREND',
    GET_POINT_TABLE: 'GET_POINT_TABLE',
    SET_POINT_TABLE: 'SET_POINT_TABLE',
    GET_HOME_PHOTOSHOOT: 'GET_HOME_PHOTOSHOOT',
    SET_HOME_PHOTOSHOOT: 'SET_HOME_PHOTOSHOOT',
    GET_HOME_PHOTOSHOOT_BY_SEASON: 'GET_HOME_PHOTOSHOOT_BY_SEASON',
    SET_HOME_PHOTOSHOOT_BY_SEASON: 'SET_HOME_PHOTOSHOOT_BY_SEASON',
    GET_PHOTOSHOOT_DETAILS: 'GET_PHOTOSHOOT_DETAILS',
    SET_PHOTOSHOOT_DETAILS: 'SET_PHOTOSHOOT_DETAILS',
    GET_PHOTOSHOOT_MAP_DATA: 'GET_PHOTOSHOOT_MAP_DATA',
    SET_PHOTOSHOOT_MAP_DATA: 'SET_PHOTOSHOOT_MAP_DATA',
    GET_REALTIME_MAP_DATA: 'GET_REALTIME_MAP_DATA',
    SET_REALTIME_MAP_DATA: 'SET_REALTIME_MAP_DATA',
    GET_CHECKIN_TREND: 'GET_CHECKIN_TREND',
    SET_CHECKIN_TREND: 'SET_CHECKIN_TREND',
    GET_CHECKIN_MAP: 'GET_CHECKIN_MAP',
    SET_CHECKIN_MAP: 'SET_CHECKIN_MAP',
    GET_CHECKIN_TABLE: 'GET_CHECKIN_TABLE',
    SET_CHECKIN_TABLE: 'SET_CHECKIN_TABLE',
    SET_PICTREE_VIEW: 'SET_PICTREE_VIEW',
    GET_PHOTOSHOOT_TREND: 'GET_PHOTOSHOOT_TREND',
    SET_PHOTOSHOOT_TREND: 'SET_PHOTOSHOOT_TREND',
    GET_PHOTOSHOOT_DENCHU_KAISU_TREND: 'GET_PHOTOSHOOT_DENCHU_KAISU_TREND',
    SET_PHOTOSHOOT_DENCHU_KAISU_TREND: 'SET_PHOTOSHOOT_DENCHU_KAISU_TREND',
    GET_USER_LIST: 'GET_USER_LIST',
    SET_USER_LIST: 'SET_USER_LIST',
    SET_PICTREE_USER_PAGE_NUMBER: 'SET_PICTREE_USER_PAGE_NUMBER',
    GET_USER_DATA: 'GET_USER_DATA',
    GET_CHART_SIZE: 'GET_CHART_SIZE',
    SET_CHART_SIZE: 'SET_CHART_SIZE',
    SET_MAP_TYPE: 'SET_MAP_TYPE',
    GET_MISSION_DATA: "GET_MISSION_DATA",
    SET_MISSION_DATA: "SET_MISSION_DATA",
    SET_MISSION_SUMMARY_DATA: "SET_MISSION_SUMMARY_DATA",
    GET_MISSION_RANKING_DATA: "GET_MISSION_RANKING_DATA",
    SET_MISSION_RANKING_DATA: "SET_MISSION_RANKING_DATA",
    GET_REWARD_COIN_SUMMARY_DATA: "GET_REWARD_COIN_SUMMARY_DATA",
    SET_REWARD_COIN_SUMMARY_DATA: "SET_REWARD_COIN_SUMMARY_DATA",
    GET_REWARD_COIN_DATA: "GET_REWARD_COIN_DATA",
    SET_REWARD_COIN_DATA: "SET_REWARD_COIN_DATA",
    GET_REWARD_COIN_RANKING_DATA: "GET_REWARD_COIN_RANKING_DATA",
    SET_REWARD_COIN_RANKING_DATA: "SET_REWARD_COIN_RANKING_DATA",
    GET_PERIOD_TYPE: "GET_PERIOD_TYPE",
    SET_PERIOD_TYPE: "SET_PERIOD_TYPE",
    SET_SELECTED_SEASON: "SET_SELECTED_SEASON",
    SET_SELECTED_MISSION: "SET_SELECTED_MISSION",
    GET_SEASON_DATA: "GET_SEASON_DATA",
    SET_SEASON_DATA: "SET_SEASON_DATA",
    GET_HOME_PHOTOSHOOT_NTT: 'GET_HOME_PHOTOSHOOT_NTT',
    SET_HOME_PHOTOSHOOT_NTT: 'SET_HOME_PHOTOSHOOT_NTT',
    GET_PHOTOSHOOT_TREND_NTT: 'GET_PHOTOSHOOT_TREND_NTT',
    SET_PHOTOSHOOT_TREND_NTT: 'SET_PHOTOSHOOT_TREND_NTT',
    GET_HOME_CHECKIN_NTT: 'GET_HOME_CHECKIN_NTT',
    SET_HOME_CHECKIN_NTT: 'SET_HOME_CHECKIN_NTT',
    GET_CHECKIN_TREND_NTT: 'GET_CHECKIN_TREND_NTT',
    SET_CHECKIN_TREND_NTT: 'SET_CHECKIN_TREND_NTT',
    GET_PHOTOSHOOT_MAP_DATA_NTT: 'GET_PHOTOSHOOT_MAP_DATA_NTT',
    SET_PHOTOSHOOT_MAP_DATA_NTT: 'SET_PHOTOSHOOT_MAP_DATA_NTT',
}

const A2E = {
    GET_A2E_CAMPAIGNS: 'GET_A2E_CAMPAIGNS',
    SET_A2E_CAMPAIGNS: 'SET_A2E_CAMPAIGNS',
    GET_A2E_CAMPAIGN_META_BY_ID: 'GET_A2E_CAMPAIGN_META_BY_ID',
    SET_A2E_CAMPAIGN_SELECT: 'SET_A2E_CAMPAIGN_SELECT',
    SET_CUSTOM_DATE_RANGE: 'SET_CUSTOM_DATE_RANGE',
    SET_ALIAS: 'SET_ALIAS',
    SET_REPORT_TAB: 'SET_REPORT_TAB',
    GET_A2E_DATA_SUMMARY: 'GET_A2E_DATA_SUMMARY',
    SET_A2E_DATA_SUMMARY: 'SET_A2E_DATA_SUMMARY',
    GET_A2E_DATA_BY_DATE: 'GET_A2E_DATA_BY_DATE',
    SET_A2E_DATA_BY_DATE: 'SET_A2E_DATA_BY_DATE',
    GET_A2E_DATA_BY_CREATIVE: 'GET_A2E_DATA_BY_CREATIVE',
    SET_A2E_DATA_BY_CREATIVE: 'SET_A2E_DATA_BY_CREATIVE',
    GET_A2E_DATA_BY_QUIZ: 'GET_A2E_DATA_BY_QUIZ',
    SET_A2E_DATA_BY_QUIZ: 'SET_A2E_DATA_BY_QUIZ',
    SET_TAB_DATA_LOADER: 'SET_TAB_DATA_LOADER',
}

const ENDPOINT = {
    POI_SEARCH: "/pois/search",
    POI_META: "/pois/meta_data",
    PROV: '/pois/divisions/Canada/provinces',
    CITY: '/pois/divisions/Canada/{province}/cities',
    PRODUCT_TYPE: '/pois/extra_fields',
    CREATE_AFFILIATOR: '/affiliate/create',
    AFFILIATOR_LIST: '/affiliate/all',
    AFFILIATOR_LIST_BY_KEY: '/affiliate/list/',
    AFFILIATOR_BY_ID: '/affiliate/',
    AFFILIATOR_GROUP: '/affiliate/group',
    UPDATE_AFFILIATOR: '/affiliate/update',
    DELETE_AFFILIATOR: '/affiliate/delete',
    TREND_DATA_BY_AID: '/affiliate/trend/',
    TREND_DATA_BY_AID_GA: '/ga/trend/',
    USERS_DATA_BY_AID: '/users/',
    GET_ALL_BI_LIST_USERS: '/affiliate/list/all',
    GET_REG_DATA_BY_AID: '/ga/events/',
    GET_DATA_FROM_GA: '/ga/events/all',
    GET_DATA_JTCB_FROM_ES: '/es/jtcb/',
    GET_DATA_DAA_FROM_ES: '/es/daa/',
    GET_HOME_TOTAL_STATS: '/api/data/stats',
    GET_DATA_USERS: '/api/data/users',
    GET_DATA_RDS: '/api/data/rds',
    GET_DEP_PRICE: '/api/data/dep',
    GET_DEP_SALES: '/api/data/dep/sales',
    GET_DATA_ES_DAA: '/api/data/es/daa',
    GET_DATA_SXI: '/api/data/sierra',
    GET_DATA_SXI_DENCHU: '/api/data/denchu/coffret',
    GET_DATA_SXI_DENCHU_STG: '/api/data/denchu/coffret/stg',
    GET_DATA_SXI_A2E: '/api/data/a2e/alicia',
    GET_DATA_SXI_A2E_STG: '/api/data/a2e/alicia/stg',
    GET_DATA_API: "/api/data",
}

export {
    AFFILIATE,
    CAMPAIGN,
    USERS, MAP,
    PAGE,
    GA,
    HOME,
    PUZZLE,
    PUZZLE_GEO,
    DATA,
    DATA_LOAD,
    NFTDEP,
    LUCKYFARMER,
    COOKINBURGER,
    DRAGONRAMEN,
    GRAFITTIRACER,
    KAMUIVERSE,
    JTCB,
    KPI,
    LOSTARCHIVE,
    SOULFUSERS,
    PICTREE,
    A2E,
    ENDPOINT,
};
